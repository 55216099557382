.dots{
    width: 100%;
    margin: 0 auto;
    // padding-top: 24px;
    ul{
        list-style: none;
        display: inline-flex;
        li{
           width: 8px;
            height: 8px;
            margin: 0;
            @include RLDir(margin,right,3px);
            border-radius: 50%;
            background-color: $background-color;
           
        }
    }
     &__active{
                 background-color: $gray !important;
                  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
             }

}



