.not_found_img {
  width: 65%;
  padding-bottom: 5px;
}

.not_found_title {
  font-size: 25px !important;
  font-weight: 700 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.56 !important;
  letter-spacing: normal !important;
  text-align: center;
  color: #2d323e !important;
  padding-bottom: 10px;
}

.layout_container {
  height: 100%;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
