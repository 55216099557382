.fullsize{
    width: 100% !important;
}

@media(max-width:600px){
    .Drawer{
        
        &_Header{
            height: 65px;
            padding: 10px 16px !important;
            background-color: #475974;
            text-align: $right;
            color: #fafafa;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
        }
        
        &_Body{
            padding: 8px 16px;
        }
    }
}