.rec-arrow,
.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
  color:#9F9F9F !important;
  background-color: #fff !important;
  box-shadow: none !important;
}
.exhibitionlist{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightcolor;
  padding: 0 !important;
  margin: 0 !important;
  &__content{
    height: calc(100vh - 56px);
    overflow-y: scroll;
  }
  .MuiContainer-root {
  background-color: $lightcolor;
  }
  .pavilion__container{
    width: 100%;
    height: 100%;
    .backimg{
      position: absolute;
      height: 100%;
    }
  }
  .pavilion {
    height: auto;
    padding: 36px 0px 36px !important;
  }
  .pavilion__slider{
    width: 100%;
  }
  .showmore{
    margin: 20px auto 32px !important;
  }
  .btnbox{    
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .swiper-wrapper {
    flex-wrap: wrap;
  }
  .swiper-wrapper .MuiCard-root{
    margin-bottom: 30px;
    @include RLDir(margin,left,45px);
  }
}
.skeletonshowexhibitions{
  display: flex !important;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: start;
  width: 100%;
  overflow: hidden;
  margin-top: 92px !important;
  .card{
    min-width: 336px;
    width: 336px;
    display: block;
    z-index: 1;
    margin-bottom: 30px;
    @include RLDir(margin,left,45px);
    .textbox{
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn{
        margin: 16px 0px 8px;
      }
    }
  }
}
/*----------------------- new event page ------------------------*/
/*------------------------- top header --------------------------*/
.events-header-item {
  width: 100%;
  border: solid 1px #ffffff;
  background-color: #ffffff;
  text-align: center;
  padding: 8px 6px;
  outline: none;
  cursor: pointer;
  &__id {
    line-height: 1.56;
    @include fontstyle ($darkbtntitle,18px,500) ;
  }
  &__title {
    line-height: 1.56;
    @include fontstyle ($gray,9px,500) ;
  }
  &__selected {
    border-radius: 8px;
    border: solid 1px $brand-color-font-complete;
    background-color: $brand-color;
  }
  &__id-selected {
    color: #ffffff !important;
  }
}

.calendar{
  width: 294px!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  
  .iNsuIA{
    margin:0px !important;
  }
  .hkUIDp , button{
    max-width: 23px !important;
    min-width: 23px !important;
    width: 23px !important;
  }
  
}
/*------------------------- tabs --------------------------*/
.tabcontent-event{
  height: calc(100vh - 210px) !important;
}
/*-------------------- event table---------------------------*/
.eventtable{
  height: calc(100vh - 210px) !important;
  overflow-y: scroll;
  .MuiTableCell-root{
    padding: 8px !important;
  }
  &__time{
    @include fontstyle ($darkbtntitle,12px,300) ;
  }
  
}
.table_desgin {
  .eventtable__lecturer{
      .MuiCardHeader-content .MuiCardHeader-title{
      @include fontstyle ($darkbtntitle,14px,300) ;
      line-height: 1.5;
    }
    .MuiCardHeader-content .MuiCardHeader-subheader{
      @include fontstyle ($darkbtntitle,12px,200) ;
      line-height: 1.5;
      margin-top: 5px;
    }
  }
}


@media(min-width:1279px){
  .calendar{
    // padding-top: 15px;
    height: 72px;
  }
}

@media(max-width:600px){
  .eventtable{
    min-height: calc(100vh - 185px) !important;
    max-height: calc(100vh - 185px) !important;
    .MuiCardHeader-avatar{
      display: none !important;
    }
    &__time{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button{
        width: 24px;
        height: 24px;
        margin-top: 8px;
        svg{
          font-size: 15px;
        }
      }
    }
  }
 
  .event .table_desgin .MuiTableCell-body .MuiCardHeader-root{
      span:first-child{
      @include fontstyle ($darkbtntitle,12px,bold) ;
    }
    .MuiCardHeader-subheader{
      @include fontstyle ($darkbtntitle,10px,200) ;
    }
  }
}