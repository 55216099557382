.exhebition-introduction {
  //padding-top: 50px;
  
  &__title {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__content {
    padding: 5px 25px 50px;
    padding: 5px 25px 50px;
    height: calc(100% - 70px );
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__description {
    flex-direction: row;
    width: 100%;
    font-size: 14px !important;
    font-weight: 300 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: normal !important;
    text-align: $right !important;
    color: #333333 !important;
    line-height: 30px !important;
    background-color: inherit !important;
    blockquote {
      padding: 0px !important;
      margin: 0px !important;
      }
  }
  &__description p,
  &__description span,
  &__description a,
  &__description li,
  &__description div {
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    letter-spacing: normal !important;
    text-align: $right !important;
    color: #333333 !important;
    line-height: 30px !important;
    background-color: inherit !important;
  }
  &__contact {
    display: flex;
    flex-direction: row;
    @include RLDir(padding,right,250px);
    justify-content: space-between;
    &__item {
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #9f9f9f;
      word-break: break-word;
      padding: 5px;
      &__icon {
        width: 40px;
        height: 40px;
        background-color: #475974;
        border-radius: 50%;
        padding-top: 6px;
        svg {
          fill: #fff;
        }
      }
    }
  }
  &__footer{
    position: absolute;
    bottom: 0px;
    display: flex;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    width: 100%;
    height: 70px;
    left: 0px;
    right: 0px;
    padding: 0px 25px;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    &__item{
      height: 100%;
      width: 25%;
      max-width: 25%;
      overflow-x: hidden;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #9F9F9F;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      .MuiCardHeader-avatar {
        margin-left: 0px;
      }
      .logo-cover{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .disable{
        background-color: #9f9f9f;
        cursor: auto !important;
      }
      .active{
        background-color: #475974;
      }
      P{
        overflow: hidden;
        @include RLDir(margin,left,10px);
        direction: ltr;
        height: 24px;
      }
      .phoneitem{
        display: flex !important;
        align-items: center !important;
        flex-direction: inherit !important;
        color: #9F9F9F !important;
      }
    }
    &__item:active {
      background-color: #fff;
    }
    &__tell{
      width: 100%;
      display: contents;
    }
  }
}
.exhebition-members {
  background: #fff !important;
  &__groupicons {
    display: flex;
    flex-direction: row;
  }
  &__icons {
    background: #475974 !important;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @include RLDir(margin,left,5px);
    svg {
      fill: white !important;
    }
  }
}

.exhebition-emptypages-cover{
  position: relative;
  overflow-y: hidden;
  .MuiBottomNavigation-root {
    height: auto;
  }
}
.exhebition-emptypages{
  // height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow: hidden;
  text-align: center;
  img{
    width: 264px;
  }
  p{
    font-size: 14px;
    font-weight: 400;
    margin-top: 25px;
    line-height: 1.8;
  }
}


.MuiTableCell-head {
  font-family: "IRANSansFa" !important;
}
.MuiTableCell-body {
  font-family: "IRANSansFa" !important;
}
.cooperation-item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-bottom: 1px solid #eee;
  .coopration_btn {
    width: 50% !important;
    margin-top: 20px;
    color: #fff;
    background-color: #475974;
  }

  &__create {
    margin-top: 35px;
    margin-bottom: 16px;
    h1 {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: $right;
      color: #2d323e;
    }
    textarea {
      width: 100%;
      border: solid 1px #eaeaea;
      height: 184px;
      margin-top: 15px;
      padding: 10px;
    }
  }
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    &__title {
      width: 50%;
    }
    &__job {
      width: 50%;
      display: flex;
      /* align-content: space-between; */
      justify-content: center;
      button {
        font-size: 12px;
      }
      svg {
        fill: #e0e0e0 !important;
      }
    }
    &__location {
      @include RLDir(padding,right,30px);
      font-size: 13px;
      color: #e0e0e0;
      display: flex;
    }
  }
  &__description {
    font-size: 12px;
    width: 90%;
  }
}
.exhebition-comments {
  padding: 15px;
  padding-bottom: 90px;

  &__header {
    height: 70px;
    background: #475974 !important;
    width: 100%;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__icon {
      padding-right: 20px;
      padding-left: 20px;
      align-items: center;
      display: flex;
      svg {
        fill: #fff;
      }
    }
    &__title {
      h1 {
        font-size: 14px;
      }
      h2 {
        font-size: 12px;
        margin-top: 5px;
      }
    }
    &__rate {
      left: 20px;
      position: absolute;
      text-align: $left;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 10px;
        margin-top: 5px;
      }
    }
  }
  &__list{
    height: calc(100% - 142px);
    overflow-y: scroll;
    background-color: #F8F8F8;
  }
}
.comments-item-pavilion {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fff;
  // height: 115px;
  border: 1px solid #eee;
  border-top: none;
  padding-bottom: 0px;
  position: relative;
  &__date {
    font-size: 10px;
    padding-top: 20px;
    color: #9f9f9f;
    position: absolute;
    top: 0;
    @include RLDir(null,left,20px);
  }
  .MuiListItemText-primary{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
  .MuiTypography-colorTextPrimary{
    font-size: 12px;
    color:#9F9F9F;
  }
  p{
    font-size: 12px !important;
    color:#585858;
  }
  // &__details {
  //   width: 85%;
  //   padding-top: 20px;
  //   &__name {
  //     font-size: 12px;
  //   }
  //   p {
  //     font-size: 12px;
  //   }
  // }
 
  // &__img {
  //   padding-top: 20px;
  //   @include RLDir(padding,right,10px);
  //   @include RLDir(padding,left,30px);
  //   img {
  //     width: 40px;
  //     height: 40px;
  //     border-radius: 50%;
  //     background: #eee;
  //   }
  // }
}

@media(max-width:600px){
  .exhebition-introduction{
    &__content {
      padding: 5px 17px 30px !important;
      // height: calc(100% - 100px );
      height: calc(100% - 56px );
    }
  }
  
  .tabcontent-stand {
    height: calc(100vh - 186px) !important;
  }
  .exhebition-introduction {
    &__footer{
      height: 64px;//56px;
      padding: 0px;
      // padding-bottom: 8px !important;

      &__item{
        width: 25%;
        justify-content: center;
        height: 100%;
        border-left: 1px solid #EAEAEA;
        span , span:hover , span:focus{
          color: #475974 !important;
          background-color: #fff !important;
        }
        p{
          display: none;
        }
        .disable{
          background-color: #fff !important;
          svg{color:$gray;}
        }
        .active{
          background-color: #fff !important;
          svg{color: $darkbtncolor;}
        }
      }
      
    }
  }
  
  .exhebition-comments {
    padding: 15px;
    padding-bottom: 90px;
    &__header {
      &__icon {
        padding-right: 12px;
        padding-left: 12px;
      }
      &__title {
        h2 {
          font-size: 10px;
        }
      }
    }
  }
  .comments-item-pavilion {
    &__date {
      left: 8px;
    }
    .MuiListItem-gutters {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}