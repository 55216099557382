.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
}

.text {
  color: #aaa;
}

.error-alert {
  color: #d90004;
  font-size: 15px;
  padding-bottom: 15px;
}

.value {
  font-size: 32px;
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 16px;
}
/*-------------------- title ----------------------- */
.auth {
  &__title {
    line-height: 1.56 !important;
    letter-spacing: normal !important;
    text-align: center;
    margin-bottom: 24px;
    // padding-bottom: 15px;
    @include fontstyle($darkbtntitle, 18px, 500);
  }
  &__subtitle {
    text-align: center;
    margin: 0 auto;
    line-height: 25px;
    // padding-bottom: 15px;
    @include fontstyle($darkbtntitle, 16px, bold);
  }
  &__content-step-2 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &__input-code::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(0, 0, 0, 0.12);
  }
}
/*-------------------- login ------------------------*/
.auth-panel {
  // padding-top: 60px;
  direction: $dir;
  .title {
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 10px;
    @include fontstyle($darkbtntitle, 24px, 500);
  }
  .description {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    line-height: 25px;
    @include fontstyle($gray, 14px, 300);
  }
  .image {
  }
}
.forgetpass {
  font-family: "IRANSansFa" !important;
  display: flex;
  justify-content: center;
  @include fontstyle($gray, 14px, 300);
  svg {
    @include RLDir(margin, left, 5px);
  }
  a {
    text-decoration: none !important;
  }
}

/*---------------- register ------------------*/
.termmood {
  .MuiFormControlLabel-root {
    display: initial;
  }
}
/*---------------- forget pass ------------------*/
.helptxt {
  margin-top: 10px !important;
  margin-bottom: 24px !important;
  @include fontstyle($gray, 12px, 300);
}
.button-wrapper {
  text-align: center;
  margin-top: 40px;
}

/*-------------- language button ----------------*/
.lang {
  button {
    color: #9f9f9f;
    font-size: 12px;
    border-radius: 0;
    min-width: 0px;
    position: relative;
    text-transform: capitalize;
    vertical-align: middle;
    &.active {
      color: #2da5d9;
      font-weight: bold;
    }
  }
  & > span {
    font-size: 12px;
    color: #9f9f9f;
  }
}
.MuiDrawer-root {
  .lang {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 16px;
    // .changelang{
    //   @include RLDir(margin,right,8px);
    // }
    button {
      color: #9f9f9f;
      &.active {
        color: #fff;
      }
    }
    .namalinelink {
      display: block;
      text-align: center;
      color: #fff;
      font-size: 12px;
      margin-top: 8px;
      cursor: pointer;
    }
    .namalinelink:hover,
    .namalinelink:active {
      color: #fff;
    }
  }
}

/*---------------- country input  ------------------*/
.countrycode {
  .react-tel-input {
    direction: ltr !important;
    margin-top: 16px !important;
    .form-control {
      width: 100% !important;
      height: 56px !important;
      padding: 18.5px 14px 18.5px 76px; //
    }
    //flag
    .flag-dropdown {
      width: 71px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .selected-flag {
      width: 67px;
      height: 94%;
      background-color: #fff;
    }
    .country-list {
      top: 100%;
      left: 0;
    }
    .form-control:hover {
      border: 1px solid black !important;
    }
    .form-control:focus {
      border: 2px solid black !important;
      box-shadow: 0 0 0 0px #1976d2 !important;
    }
    .form-control.invalid-number:focus {
      box-shadow: 0 0 0 0px #f44336;
    }
    .country-list {
      .country {
        text-align: initial !important;
      }
    }
    .special-label {
      background-color: transparent !important;
      top: -9px !important;
      @include RLDir(null, right, 8px !important);
      color: #a2a7a6 !important;
      font-size: 0.75em;
      text-align: right;
    }
  }

  .react-tel-input:after,
  .form-control:focus-within:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 69px;
    height: 3px;
    background-color: #fafafa;
    position: absolute;
    @include RLDir(null, right, 9px !important);
    top: -1px;
  }
}

@media (max-width: 600px) {
  /*------------- login -----------*/
  .auth {
    &__title {
      @include fontstyle(null, 16px, bold);
    }
  }
  /*----------- forget pass ---------*/
  .helptxt {
    margin-top: 48px !important;
    margin-bottom: 0px !important;
  }
  /*---------------  ---------------*/
  .authbox {
    padding: 32px 0 32px !important;
  }
}
