#root {
  background-color: #fff;
}
.index-aboutUs {
  &__btnBox {
    margin-bottom: 16px !important;
    &__buttonTeam {
      color: #fff !important;
      background-color: #2da5d9 !important;
      margin-left: 16px !important;

      &:hover {
        background-color: #57c0ed !important;
      }
    }
  }
  &__TabBox {
    margin: 16px 0;
    background-color: none !important;
  }
  &__info {
    background-color: #fff !important;
    &__infoPic {
      height: 396px;
      object-fit: contain;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;

      .media {
        height: 100%;
        overflow: hidden;
        background-size: contain !important;
      }
    }
    &__infoTextBox {
      padding: 40px !important;
      background-color: #fff !important;

      &__infoTopic {
        line-height: 32px;
        color: #2d323e !important;
        margin-bottom: 24px !important;
      }
      &__infoText {
        margin-bottom: 24px !important;
        color: #707070 !important;
        line-height: 28px !important;
      }
    }
  }
  &__jobBackground {
    background-color: #fafafa !important;
    // box-shadow: 0 16px 40px rgba($color: #a3a7b1, $alpha: 2) !important;
    // position: relative;
    // margin-bottom: 52px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 100%;
    //   left: 0;
    //   right: 0;
    //   background-color: #fff;
    //   width: 100%;
    //   height: 52px;
    // }
    &__text {
      padding: 112px 40px 88px 16px !important;

      &__title {
        margin-bottom: 24px !important;
        color: #2d323e !important;
      }
      &__content {
        color: #707070 !important;
        line-height: 28px !important;
      }
    }
    &__image {
      display: flex;
      justify-content: flex-end;
      &__pic {
        width: fit-content;
        position: relative;
        display: flex;
        img {
          height: 100%;
          max-height: 468px;
          z-index: 3;
          filter: drop-shadow(-8px 4px 6px #000);
        }
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -76px;
          background-image: url("./bg.svg");
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          z-index: 2;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: -180px;
          background-image: url("./bg.svg");
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          z-index: 1;
          filter: contrast(0) opacity(0.3);
        }

        // box-shadow: none !important;
      }
    }
  }
  &__team {
    padding-top: 40px !important;
    background: #fff;
    &__textBox {
      position: relative;
      margin-bottom: 40px !important;

      &:before {
        content: "";
        position: absolute;
        background-image: url("./teamBg.svg");
        // z-index: -111;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
      &__title {
        margin: 30px 0 !important;
        color: #2d323e !important;
        position: relative;
        z-index: 2;
      }
      &__text {
        position: relative;
        color: #707070 !important;
        line-height: 28px !important;
        margin-bottom: 40px !important;
        z-index: 2;
      }
    }

    &__memberBox {
      margin-top: 76px !important;
      background-color: #fafafa !important;
      padding-bottom: 15px !important;
      .swiper-container {
        overflow: unset;
      }
      &__member {
        padding: 52px 64px 24px 64px !important;
        position: relative;
        box-shadow: 0px 1px 4px 0px rgba(71, 89, 116, 0.12%) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 6px;
        margin-bottom: 75px !important;

        &__PersonPic {
          border-radius: 50%;
          width: 128px;
          height: 128px;
          background-color: #dcf0fa !important;
          position: absolute;
          bottom: calc(100% - 52px);
          right: 0;
          margin: auto;
          left: 0;
        }
        &__name {
          font-size: 18px !important;
          color: #585858 !important;
          margin-bottom: 8px !important;
          margin-top: 8px !important;
        }
        &__position {
          color: #98a3b1 !important;
          font-size: 12px !important;
          margin-bottom: 4px !important;
        }
        &__socials {
          .icon {
            padding: 8px !important;
          }
        }
      }
    }
  }
}
$breakpoint-mobile: 480px;

@media (max-width: $breakpoint-mobile) {
  .header-index__wrapper__box {
    p {
      font-size: 14px !important;
    }
  }
  .index-aboutUs {
    &__TabBox {
      font-size: 14px !important;
      .tabBoxes {
        button {
          padding: 6px 2px !important;
        }
      }
    }

    &__info__infoTextBox__infoTopic {
      font-size: 20px !important;
    }
  }
}
.team_container {
  text-align: center;
  .swiper-container {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    @media (max-width: 600px) {
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}
