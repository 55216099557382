.index-contactUs__map {
  position: relative;
  height: 100vh;
  max-height: 630px;
  width: 100%;
  .mapboxgl-marker {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 46px;
    height: 46px;
    padding: 4px;
    border-radius: 50%;
    background-color: #2da5d9;
    border: 6px solid #f6f6f4;
    box-shadow: 0 0 0px 2px #2da5d9;
    transition: box-shadow 0.43s ease-in-out;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #2da5d9;
      border-radius: 50%;
      transform: scale(1.9);
      box-shadow: inset 0 0 0 5px rgba(#2da5d9, 0.2);
      transition: all 0.43s ease-in-out;
    }
    &:hover {
      box-shadow: 0 0 0px 4px rgba(#2da5d9, 0.8);
      &:before {
        box-shadow: inset 0 0 0 5px rgba(#2da5d9, 0.25);
        transform: scale(2.1);
      }
    }
    img {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
    }
  }
  .mapboxgl-canvas {
    position: relative;
    outline: none;
  }
}

.header-index-conatactUs {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.index__contact {
  background-color: #fff !important;
  &__btnBox {
    margin-bottom: 16px !important;

    &__buttonChat {
      color: #fff !important;
      background-color: #2da5d9 !important;
      margin-left: 8px !important;
      margin-right: 8px !important;

      &:hover {
        background-color: #57c0ed !important;
      }
    }

    &__buttonCall {
      background-color: #fafafa !important;
      color: #7481a0 !important;
      margin-left: 8px !important;
      margin-right: 8px !important;

      &:hover {
        background-color: #eaeaea !important;
      }
    }
  }

  &__workPlaceImage {
    height: 154px;
    padding: 8px 8px;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;

    .media {
      height: 100%;
    }
  }

  &__info {
    padding: 32px;
    background-color: transparent !important;
    border: none !important;
    color: #707070 !important;
    box-shadow: none !important;

    .text {
      margin-right: 8px !important;
      color: #707070 !important;
    }

    &__link {
      display: flex;
      align-items: center;
      color: #707070 !important;
      margin-top: 16px !important;
    }

    &__socialLinks {
      display: flex;
      align-items: center;
      color: #707070 !important;
      box-shadow: 0 0 1px rgba(#707070, 5%) !important;
      padding: 8px 16px 24px 16px;

      svg {
        margin: 8px;
      }

      .socialBox {
        margin-right: 32px;
      }
    }
  }
}

$breakpoint-tablet: 768px;

@media (max-width: $breakpoint-tablet) {
}

$breakpoint-mobile: 480px;

@media (max-width: $breakpoint-mobile) {
  .index__contact__info__socialLinks {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .index__contact__info {
    .text {
      font-size: 14px !important;
    }
  }

  .index__contact__info__link {
    p {
      font-size: 14px !important;
    }
  }

  .index__contact__info__socialLinks {
    svg {
      margin: 2px;
    }

    .socialBox {
      margin-right: 4px;
    }
  }
}
.ltr{
  direction: ltr;
}