$white:#FFFFFF;
$lightcolor:#FAFAFA;
$lightsubtitle:#F8F8F8;
$lightgray:#EAEAEA;
$gray:#9F9F9F;
$darkgray:#585858;
$darkbtncolor:#475974;
$darkbtntitle:#2D323E;
$textcolor:#333333;
$darkbluebtn:#2ea5d9;
$bluebtn:#30A4E2;
$disablebtn:#b6d9f4;
$greenbtn:#13C356;
$red:#D91A3C;


@import "./base/variables";
@import "./base/mixin";
@import "./base/placeholder";
//component
@import "./component/btn";
//layout
@import "../layouts/Layouts";

@import "./global";

//fonts
@import "../assets/fonts/_font.scss";
//views
@import "../views/chat/_chat.scss";

@import "../views/user/_auth.scss";
@import "../views/events/_event.scss";
@import "../views/product/_product.scss";
@import "../views/profile/_profile.scss";
@import "../views/stands/_stand.scss";
@import "../views/stands/pictures/pictures.scss";
@import "../views/meeting/_meeting.scss";
@import "../views/home/_home.scss";
@import "../views/exhibition/_exhebition.scss";
@import "../views/expo/_expo.scss";
@import "../views/opportunity/_opportunities.scss";
@import "../component/photoUpload/_avatar.scss";
@import "../views/testarea/_test.scss";
@import "../component/Search/Search.scss";
@import "../views/common/common.scss";

//components
@import "../component/Dots/_dots.scss";
@import "../component/TextInput/_textInput.scss";
@import "../component/Button/_button.scss";
@import "../component/SelectInput/_selectInput.scss";
@import "../component/BasicSwitch/_switch.scss";
@import "../component/Modal/_modal.scss";
@import '../component/Voting/_Voting.scss';
@import '../component/ConfirmModal/ConfirmModal.scss';
@import "../views/conference/Conference.scss";
@import '../component/FullPageModal/FullPageModal.scss';
@import '../component/FileUpload/FileUpload.scss';
@import '../component/SendBox_Input/Sendbox_input';
@import '../component/FooterPagination/_FooterPagination.scss';

/*******index*****************/
@import "../views/Landing/_Landing.scss";
@import "../views/FAQ/FAQ.scss";
@import "../views/contactUs/ContactUs.scss";
@import "../views/aboutUs/aboutUs.scss";

/*******index*****************/

//Responsive
@import "./GeneralResponsive";
@import "../component/Drawer/Drawer.scss";


