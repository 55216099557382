%scrollBase{
    /* width */
&::-webkit-scrollbar {
    width: $scroll-base-width;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: $scroll-base-bg;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $scroll-base-thumb-color;
    border-radius: $scroll-base-width;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-base-thumb-color-hover;
  }
}
// %lbl-attention ___ %lbl-default ___ %lbl-action ___ %lbl-success ___ %lbl-disabled
// %btn-attention ___ %btn-default ___ %btn-action ___ %btn-success ___ %btn-disabled
$btns:
  "attention" $btn-attention-bg $btn-attention-bg-hover $btn-attention-font,
  "default"   $btn-default-bg   $btn-default-bg-hover   $btn-default-font,
  "action"    $btn-action-bg    $btn-action-bg-hover    $btn-action-font,
  "success"   $btn-success-bg   $btn-success-bg-hover   $btn-success-font,
  "disabled"  $btn-disabled-bg  $btn-disabled-bg-hover  $btn-disabled-font;

@each $name, $color,$color-hover ,$color-font  in $btns {
  %btn-#{$name} {
    background-color: $color!important;
    border: 1px solid $color!important;
    color:$color-font !important;
    &:hover{
      background-color: $color-hover;
      border: 1px solid $color-hover;
    }
  }
  %lbl-#{$name} {
    background-color: $color;
    color:$color-font !important;
  }
}

%bg-transition{
  transition: background-color 1s linear;
}
