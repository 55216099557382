.voting{
    padding: 10px 0px;
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        &__card{
            .MuiCardHeader-avatar {
                margin-left: 8px !important;
            }
            .MuiCardHeader-title{
                font-size: 14px !important;
                font-weight: 400 !important;
                color: #2D323E;
            }
            .MuiCardHeader-subheader{
                font-size: 10px !important;
                color:#9F9F9F
            }
        }
        .MuiCardHeader-root {
            display: flex;
            padding: 0px;
        }
    }
    &__btns{
        display: flex;
        justify-content: space-between;
        .MuiButton-containedPrimary {
            border: solid 1px #30a4e2 !important;
            background-color: #2da5d9 !important;
            font-size: 14px !important;
            font-weight: bold !important;
        }
    }
    &__desc{
        height: 100px;
        margin-bottom: 20px;
        padding: 16px;
        border-radius: 10px;
        background-color: #eaeaea;
        font-size: 14px;
        text-overflow: ellipsis;
    }
}

@media(min-width:600px){
    .voting {
        width: 400px;
    }
}