/*-------------------------------- fonts --------------------------------------*/
@mixin fontstyle($color, $size, $weight) {
    @if ($color!=null) {
        color: $color !important;
        font-size: $size !important;
        font-weight: $weight !important;
    } @else {
        font-size: $size !important;
        font-weight: $weight !important;
    }
}

/*-------------------------------- button --------------------------------------*/

@mixin btnstyle($bgcolor, $color, $font, $weight, $height, $width) {
    background-color: $bgcolor !important;
    color: $color !important;
    box-shadow: $btn-box-shadow !important;
    font-size: $font !important;
    font-weight: normal !important;
    height: $height !important;
    width: $width;
    white-space: nowrap;
    cursor: pointer !important;
}
@mixin btnStyleByVal($name, $font, $weight, $height, $width) {
    @extend %btn-#{$name};
    box-shadow: $btn-box-shadow !important;
    font-size: $font !important;
    font-weight: normal !important;
    height: $height !important;
    width: $width;
    white-space: nowrap;
    cursor: pointer !important;
}
@mixin simplebtn($bgcolor, $color, $font, $weight, $height, $width) {
    background-color: $bgcolor !important;
    color: $color !important;
    font-size: $font !important;
    font-weight: $weight !important;
    height: $height !important;
    width: $width;
    white-space: nowrap;
    cursor: pointer !important;
}

@mixin scroll($width,$height ,$TrackBg, $TrackShadow, $TrackRadius, $thumbBg, $thumbBgHover, $thumbRadius) {
    /* width */
    &::-webkit-scrollbar {
        width: $width;
        height:$height ;
    }

    &::-webkit-scrollbar-track {
        background: $TrackBg;
        box-shadow: $TrackShadow;
        border-radius:  $TrackRadius;
    }

    &::-webkit-scrollbar-thumb {
        background: $thumbBg;
        border-radius: $thumbRadius;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $thumbBgHover;
    }
}
