.text-input {
  border-radius: 4px;
  font-family: "IRANSansFa" !important;
  //border: solid 1px rgba(0, 0, 0, 0.12) !important;
}
.text-input .MuiInputBase-root {
  font-size: 14px !important;
  font-weight: 300 !important;
  height: 56px !important;
  // border: solid 1px rgba(0, 0, 0, 0.12) !important;
}
