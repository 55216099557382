$headersize: 71px;
.preload-item {
  &__content {
    width: 100%;
  }
}
.product-ratingbox {
  background-color: #efd303;
  padding: 2px 6px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-end;
  border-radius: 4px;
  color: #ffff;
  .sum {
    font-size: 14px;
    margin-left: 2px;
  }
  .count {
    font-size: 12px;
  }
}

/*--------------------------- pagination ---------------------------*/
.products_footer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 9998;
  position: absolute;
  bottom: 0;
  @include RLDir(null, "left", 0);
  background-color: white;
}
/*--------------------------- product list ---------------------------*/
.product-list {
  height: calc(100vh - 57px);
  &__right-side {
    width: 100%;
    padding: 14px 21px 13px 21px;
    border-top: none;
  }
  &__products {
    position: relative;
    height: calc(100vh - 57px);
    &__show {
      overflow-y: auto;
      height: calc(100% - 56px) !important;
    }
    &__between {
      height: 40px;
      background-color: $lightsubtitle;
    }
  }
}
/*--------------------- product header ----------------*/
.product-header {
  flex-grow: 1;
  // border: solid 1px #eaeaea;
  .AppBar {
    height: 73px !important;
    border: solid 1px $lightgray;
    background-color: $darkbtncolor;
    box-shadow: none !important;
    .MuiToolbar-regular {
      min-height: 100%;
      justify-content: space-between;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .title {
      flex-grow: 1;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: $right;
      padding: 0px !important;
      @include RLDir("margin", "left", 9px);
      .MuiTypography-displayBlock {
        @include fontstyle($lightsubtitle, 16px, bold);
      }
      .MuiAvatar-img {
        background-color: $white !important;
      }
    }
    .subTitle {
      line-height: 1.58;
      letter-spacing: normal;
      text-align: $left;
      @include fontstyle($lightsubtitle, 12px, 300);
    }
    .avatar {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #eaeaea;
      @include RLDir("margin", "left", 15px);
    }
  }
}

/*-------------------------- product filter -----------------------*/
.product_filter-list {
  width: 100%;
  height: 100vh;
  padding: 14px 16px;
  border-top: none;
  @include RLDir("border", "left", 1px solid #eee);
}
/*--------------------------- product item ----------------------*/
.product-item {
  display: flex;
  flex-direction: row;
  padding: 22px;
  @include RLDir("border", "right", solid 1px #e0e0e0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
  &__avatar {
    @include RLDir("padding", "left", 10px);
    img {
      width: 100px;
      height: 84px;
      object-fit: contain;
      border: 1px solid #eee;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 127px !important;
    overflow: hidden;
    position: relative;

    &__title {
      @media (min-width: 0px) and (max-width: 350px) {
        display: block;
      }
      @media (min-width: 600px) {
        display: block;
      }
      flex-direction: row;
      width: 100%;
    }
    &__rate {
      text-align: $left;
      justify-content: flex-end;
      position: absolute;
      top: 0;
      @include RLDir(null, left, 0);
      span {
        font-size: 1.2rem;
      }
    }
    &__title h2 {
      line-height: 1.57;
      letter-spacing: normal;
      text-align: $right;
      margin-bottom: 5px;
      float: $right;
      @include fontstyle($textcolor, 14px, 300);
      @media (min-width: 0px) and (max-width: 350px) {
        width: 100%;
      }
      @media (min-width: 600px) {
        width: 65%;
      }
    }
    &__category {
      line-height: 1.58;
      letter-spacing: normal;
      text-align: $right;
      margin-bottom: 5px;
      @include fontstyle($gray, 12px, 300);
    }
    &__description {
      @include fontstyle($darkgray, 12px, 300);
      @media (min-width: 0px) and (max-width: 350px) {
        max-height: 100px;
        overflow: hidden;
      }
      @media (min-width: 600px) {
        flex-direction: row;
        width: 100%;
        letter-spacing: normal !important;
        text-align: $right !important;
        line-height: 25px !important;
        max-height: 75px !important; /* line-height * 3 */
        overflow: hidden !important;
        background-color: inherit !important;
      }
    }
    &__description,
    &__description p,
    &__description span,
    &__description a,
    &__description li,
    &__description div,
    &__description h1,
    &__description h2,
    &__description h3,
    &__description h4,
    &__description h5,
    &__description h6,
    &__description b,
    &__description {
      * {
        font-family: "IRANSansFa" !important;
        letter-spacing: normal !important;
        text-align: right !important;
        line-height: 25px !important;
        max-height: 75px !important; /* line-height * 3 */
        height: auto;
        overflow: hidden !important;
        background-color: inherit !important;
        @include fontstyle($darkgray, 12px, 300);
      }
    }
    &__description div > *:empty,
    &__description div br {
      display: none;
    }
  }
}

/*--------------------------- product details ----------------------*/
.product-detail-header {
  height: $headersize;
  flex-grow: 1;
  .AppBar {
    background-color: $white !important;
    height: 71px;
    box-shadow: none;
    .product-detail {
      text-align: $right;
      display: inherit;
      align-items: center;
    }
    .product-title {
      color: #585858;
      @include RLDir("margin", "left", 15px);
      @include fontstyle($darkgray, 16px, bold);
      display: flex;
      flex-direction: column;
      span {
        display: none;
        @include fontstyle($gray, 10px, bold);
      }
    }
    .company-detail:first-child {
      display: none;
    }
    .company-detail {
      text-align: $left;
      align-items: center;
      .title {
        @include fontstyle($darkbtntitle, 12px, bold);
      }
    }
    .back-icon {
      background-color: #e0e0e0;
      min-width: fit-content;
      padding: 7px 0;
      @include RLDir("padding", "left", 2px);
      @include RLDir("padding", "right", 10px);
      border-radius: 50%;
      @include RLDir("margin", "right", 10px);
      svg {
        padding-left: 8px;
      }
    }

    .MuiToolbar-regular {
      height: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.product-detail {
  &__right-side {
    width: 100%;
    padding: 14px 21px 13px 21.1px;
    border-top: none;
  }
  &__slider-panel {
    direction: $dir-ltr !important;
    height: 330px !important;
  }
  &__slider-preload {
    direction: $dir !important;
  }
  &__slider-panel {
    .carousel-wrapper {
      position: relative;
      max-width: 831px;
      height: 330px;
      margin: 0 auto;
      .carousel {
        .slide {
          img {
            height: 330px;
            width: auto;
          }
        }
      }
    }
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
}

/*--------------------- product details info tab ----------------*/

.product-detail-info {
  display: flex;
  flex-direction: row;
  padding: 22px;
  // @include RLDir("border","right",solid 1px #e0e0e0);
  // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-between;
  }
  &__title {
    line-height: 1.57;
    letter-spacing: normal;
    text-align: $right;
    margin-bottom: 5px;
    @include fontstyle($darkgray, 16px, bold);
  }
  &__subtitle {
    line-height: 1.57;
    letter-spacing: normal;
    text-align: $right;
    margin-top: 5px !important;
    @include fontstyle($gray, 12px, 300);
  }
  // &__company {
  //   .MuiButton-contained {
  //     color: #475974;
  //     background-color: #475974;
  //     color: #F8F8F8;
  //     font-size: 12px;
  //   }
  //   text-align: $left;
  // }
  &__content {
    letter-spacing: normal;
    text-align: $right;
    * {
      line-height: 28px;
      @include fontstyle($darkgray, 14px, 300);
    }
  }
}

/*--------------------- comment ----------------------*/
.comment-item {
  display: flex;
  flex-direction: row;
  // padding: 22px;
  @include RLDir(border, right, solid 1px #e0e0e0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &__avatar {
    width: 8%;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      justify-content: space-between;
    }
    &__title {
      display: flex;
      align-items: end;
      p {
        font-size: 14px !important;
        color: #333333;
      }
    }
    &__date {
      font-size: 12px !important;
      color: #9f9f9f;
    }
    &__rate {
      text-align: $left;
      display: flex;
      align-items: center;
      width: auto;
      justify-content: flex-end;
      @include RLDir(margin, right, 10px);
    }
    // &__title p {
    //   font-size: 14px;
    //   font-weight: 400;
    //   letter-spacing: normal;
    //   text-align: $right;
    //   color: #333333;
    //   margin-bottom: 5px;
    //   // width: 10%;
    // }
  }
  &__job-title {
    font-size: 12px !important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    text-align: $right;
    color: #9f9f9f;
    margin-bottom: 5px;
  }
  &__desc {
    font-size: 12px !important;
    color: #585858;
  }
  &__delete {
    float: left;
    font-size: 18px !important;
    color: #d91a3c;
  }
}

.comments {
  height: calc(100vh - 532px) !important; //300px !important;
  overflow: hidden;
  &__list {
    overflow-y: scroll;
    height: calc(100vh - 588px) !important; //620 >> 220px !important;
  }
  &__footer {
    z-index: 9998;
  }
}
.comment-rate {
  font-size: 19px !important;
  // flex-direction: row-reverse;
}

.comment-footer {
  @include RLDir("border", "right", 1px solid rgba(0, 0, 0, 0.12));
  background-color: #eee;
  height: 85px !important;
  .send-loading svg {
    margin: 0;
    color: #fff;
  }
}

.nomessage-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 40px;
  img {
    width: 100px;
  }
  p {
    font-size: 12px;
  }
}

.product-detail-left {
  height: auto;
  overflow-y: scroll;
}

@media (max-width: 900px) {
  .product_filter-list {
    border-left: none !important;
  }
}
@media (max-height: 700px) {
  .comments {
    height: 300px !important;
    &__list {
      height: 244px !important;
    }
  }
}

@media (max-width: 600px) {
  .product-header {
    .AppBar {
      height: 56px !important;
      .title {
        @include fontstyle(null, 14px, bold);
        padding: 0px !important;
      }
    }
    .MuiCardHeader-avatar {
      @include RLDir(margin, "left", 8px);
    }
    .MuiToolbar-gutters {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  /*--------------------------------------------*/
  .product-list {
    height: calc(100vh - 58px);
    &__products {
      height: calc(100vh - 130px);
      &__between {
        height: 25px;
      }
      &__show {
        height: calc(100vh - 182px) !important;
      }
    }
  }
}
/*--------------------------------------------*/
.product-item {
  padding: 11px;
  &__content {
    max-height: 108px !important;
    &__category {
      @include fontstyle(null, 10px, bold);
    }
    &__description,
    &__description p,
    &__description span,
    &__description a,
    &__description li,
    &__description div,
    &__description h1,
    &__description h2,
    &__description h3,
    &__description h4,
    &__description h5,
    &__description h6,
    &__description b,
    &__description {
      * {
        line-height: 20px !important;
      }
    }
  }
  &__avatar {
    img {
      width: 90px;
      height: 70px;
    }
  }
}
/*--------------------comments ------------------*/

.comment-item {
  &__content {
    &__rate {
      flex-direction: column;
      margin-top: 4px;
      span:last-child {
        @include RLDir(margin, right, 0px);
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  &__job-title {
    font-size: 10px;
  }
  &__desc {
    font-size: 12px;
  }
}
.comment-send-icon {
  @include RLDir(margin, left, 0px);
}
/*--------------------------------------------*/

.product-detail-header {
  .AppBar {
    .product-title {
      font-size: 14px !important;
      span {
        display: block;
      }
    }
    .product-detail {
      justify-content: space-between;
      width: 100%;
      display: flex;
    }
    .company-detail {
      .MuiButton-contained {
        display: none;
      }
    }
  }
}

.product-detail-info {
  &__header {
    margin-bottom: 16px;
  }
  &__title {
    font-size: 14px !important;
  }
  &__subtitle {
    @include fontstyle(null, 10px, bold);
  }
}

.products_footer {
  @include RLDir(padding, right, 20px);
}

.tour_header {
  background-color: #eee;
  padding: 5px 0;
  margin-bottom: 10px;
}
