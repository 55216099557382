.makeStyles-content-119 {
  margin-top: 0 !important;
}

.conference {
  width: 100% !important;
  background-color: #ddd;
  height: 100% !important;
  margin-top: 0 !important;
  border: none !important;
  padding: 0 !important;
  .video {
    width: 100% !important;
    height: 100% !important;
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
  }

  .h_iframe-aparat_embed_frame {
    position: relative;
  }
  .h_iframe-aparat_embed_frame .ratio {
    display: block;
    width: 100%;
    height: auto;
  }
  .h_iframe-aparat_embed_frame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
