.table_footer{
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: #fff;
    width: 100%;
    height: 56px !important;
    div{
        display: flex;
        justify-content: flex-end;
    }
    div:first-child+p{
        display: none !important;
    }
        
  }
  .table_footer div {
        div:first-child {
        display: none !important;
        }
        // background-color: red;
        // svg:last-child{
        //     display: none;
        // }
    }
    .table_footer div>div>svg {
        display: none !important;
        // color:red;
    }