body {
  position: relative;
}

/*--------------------header title --------------*/
.header_title {
  @media only screen and (max-width: 600px) {
    width: 200px;
  }
  @include fontstyle($darkbtntitle, $h-page-title-size, 500);
}
.expo_header_title {
  @include fontstyle($lightsubtitle, 18px, 500);
}
.minimenu_header {
  li span {
    @include fontstyle($darkbtntitle, 14px, bold);
  }
  svg {
    fill: $darkbtncolor;
  }
}


/*--------------------  --------------*/
.Logo_img_Drawer {
  @include RLDir(padding, right, 10px);
  width: 40%;
}
.Logo_auth_img_Drawer {
  height: 27px;
  display: block;
}

.expoheader-icon {
  @include RLDir(margin, left, 10px);

  svg {
    color: #fff;
  }
}
#menu-list-grow .exhibition-list{
  font-family: 'IRANSansFa';
  font-size: 12px;
  font-weight: bold;
  min-height: 40px;
}
.exhibitionListIcon{
  padding: 0px !important;
}
.exhibitionListIcon:hover, .exhibitionListIcon:active {
  background: transparent !important;
}
.expoheader-nav-buttons {
  margin-right: 30px;
  .next-btn {
    border: 1px solid #eee;
    color: #eee;
    font-weight: normal;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  .next-btn:hover {
    text-decoration: none;
    background-color: rgba(74, 74, 74, 1);
  }
  .prev-btn {
    border: 1px solid #eee;
    color: #eee;
    font-weight: normal;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
  }
  .prev-btn:hover {
    text-decoration: none;
    background-color: rgba(74, 74, 74, 1);
  }
  .btn-nav-text {
   font-size: $font-size-md;
  }
  @media (max-width: $container-large-mobile) {
    %btn {
      min-width: 40px;
      padding: 5px 7px;
      .MuiButton-startIcon,
      .MuiButton-endIcon {
        margin-right: 0px;
        margin-left: 0px;
      }
    }
    .next-btn {
      @extend %btn;
    }
    .prev-btn {
      @extend %btn;
    }
    .btn-nav-text {
      display: none;
    }
  }
}

.Divider_showstate {
  display: none;
}

.MiniMenu {
  top: 55px !important;
  left: unset !important;
  @include RLDir(null, left, 60px !important);
  z-index: 99999999;
}

.maindrawer_title {
  padding-top: 0px !important;
}
.dashboard-drawer {
  .MuiListItem-gutters {
    padding: 0px !important;
  }

  .MuiListItem-root {
    padding: 0px !important;
  }
  .sidebar-list {
    padding-top: 0 !important;
  }
}
.maindrawer_title {
  a {
    display: flex;
    flex-direction: row;
    color: #fff;
    width: 100%;
    padding: 8px 16px !important;
  }

  .active {
    background-color: #475974 !important;
    span{
      font-weight: 900 !important;
    }
  }

  .MuiTypography-displayBlock {
    display: block;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    .conferencealert {
      position: absolute;
      height: 50%;
      left: 0;
    }
  }
}

@media (max-width: 991px) {
  .landing-header__action {
    .register,
    .login {
      display: none;
    }
    .menu {
      display: block !important;
    }
  }
}

@media (max-width: $container-large-mobile) {
  /*--------------------header title --------------*/
  .header_title {
    @include fontstyle(null, $h-page-title-size, bold);
  }
  .expo_header_title {
    @include fontstyle($lightcolor, 16px, bold);
  }
  // .header_title {
  //   span {
  //     @include fontstyle (null,16px,bold)
  //   }

  // }

  .Divider_showstate {
    display: block;
  }

  .MiniMenu {
    top: 10px !important;
    @include RLDir(null, left, 48px !important);
  }

  .Logo_img_Drawer {
    width: 125px;
  }
}

.reactour__helper button.btn_close {
  transform: rotate(0deg) !important;
  border: solid 1px #707070 !important;
  @include btnstyle($darkbtncolor, $lightcolor, 14px, bold, 30px, auto);
  &:hover {
    background-color: $darkbtncolor !important;
  }
}

.reactour__helper p {
  padding-bottom: 15px !important;
  text-align: right;
  padding-right: 10px;
  line-height: 20px;
}

.menu-lang {
  ul {
    position: absolute;
    margin-top: 1px;
    font: 10px;
    bottom: 100%; /* added this attribute */
    background: none !important;
    border: thin solid rgba(77, 77, 77, 0.3);

    li:hover,
    li:focus {
      background-color: #475974 !important;
    }
  }
  span[class^="ReactFlagsSelect-module_label"] {
    padding-right: 10px;
  }

  span[class^="ReactFlagsSelect-module_selectOptionValue"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
