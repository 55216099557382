$darkcolor: #2d323e;
$lightcolor: #9f9f9f;
$darkblue: #475974;
.MuiPaper-root {
  // background-color: $body-bg !important;
}
.avatar-spacing{
  margin-top: 15px;
}
.share-link{
  background: #EAEAEA;
  width: 35px !important;
  height: 35px !important;
  border-radius: 100%;
  padding: 5px;
  cursor: pointer;
  transition: all 0.5s ease-in-out !important;
}

.share-link:hover{
  background-color: #2f3341;
  color: white;
  transition: all 0.5s ease-in-out;
}

.bottom-spacing{
  margin-bottom: 20px;
}
.link-share-menu .MuiMenu-paper{ 
  min-width: 0px;
}
// .space__bottom{
//   margin-bottom: 22px;
// }

.info-company {
  padding: 0px 16px 4px !important;
  &__logo {
    // margin-bottom: 8px !important;
    // margin-top: 16px !important;
    &__avatar {
      width: 64px !important;
      height: 64px !important;
      object-fit: contain;
      margin-top: 5px;
    }
  }
  &__title {
    color: $darkcolor !important;
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  &__about {
    // margin: 0px;
    margin: 5px 0px 50px;
    &__comm {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 375px;
      width: 285px !important;
      margin: 0 auto;
      @media only screen and (max-width: 768px) {
        width: 312px !important;
      }
      @media (min-width: 1073px) and (max-width: 1246px) {
        width: 218px !important;
      }
      @media (min-width: 960px) and (max-width: 1072px) {
        width: 184px !important;
      }
      &__icon {
        height: $btn-icon-w-md;
        width: $btn-icon-w-md;
        display: flex;
        align-items: center;
        /* padding: 10px; */
        box-shadow: $stand-item-shadow;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #fff;
        color: $blue-dark;
        @extend %bg-transition;
        svg {
          transition: fill 1s linear;
        }
        &:hover:not(.disabled) {
          background-color: $blue-dark;
          color: #fff;
          box-shadow: $stand-item-shadow-hover;
          svg {
            fill: white;
          }
        }
      }
      &__data {
        color: #585858;
        font-size: 10px;
      }
    }
  }
}
#showheader {
  &.img-container {
    svg{
      height: 100%;
    }
   
    .typec_container {
      -moz-box-shadow: 0 0 0 2px $stands_background;
      -webkit-box-shadow: 0 0 0 2px $stands_background;
      box-shadow: 0 0 0 2px $stands_background;
      position: relative;
      z-index: 0;
    }
  }
}
.offline__mode__wrapper{
  width: 300px;
  color: #9D9D9D;
  text-align: center;
  position: relative;
  .offline__mode__text{
    position: absolute;
    bottom: 75px;
    right: 0;
    left: 0;
  }
}
.stand-product {
  height: calc(100vh - 123px) !important;
  &__show {
    height: calc(100vh - 174px) !important;
  }
}
.h-140{
  height: 140px;
}
.pavilion-info-wrapper .stand-play-icon{
  display: block;
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 6px;
  color: black;
  border-radius: 100%;
}
.space_between_online_icons{
  justify-content: stretch;
}
.stand-static-detail {
  // padding-top: 24px;
  padding: 22px;
  position: relative;
  z-index: 100;

  .iconsbox {
    &__icons {
      &__item {
        &__box {
          width: 56px;
          height: 88px;
          border-radius: 25px !important;
          svg {
            $w: 32px;
            width: $w;
            height: $w;
          }

          &.blue {
            svg {
              fill: $blue !important;
            }
          }
          &.yelow {
            svg {
              fill: $base-yellow !important;
            }
          }
          &.gray {
            svg {
              fill: $gray !important;
            }
          }
          &.green {
            svg {
              fill: $green !important;
            }
          }
          p {
            line-height: 16px;
            font-size: $font-size-lg !important;
            color: $blue-dark !important;
          }
        }
      }
    }
  }
}
.stand-detail-drawer{
  text-align: center;
  width: 200px;
  margin: auto;
}

.booth__wrapper{
  position: relative;
}

.arrow-wrapper{
  float: right;
  background-color: white;
  color: #2c2c2c;
  margin-top: 12px;
  border-radius: 100%;
  font-size: 45px !important;
  padding: 5px;
  box-shadow: 0px 0px 10px -2px #bfbfbf;
}

.arrow__icon__wrapper{
  top: 200px;
  float: right;
  position: absolute;
  background-color: white;
  color: #2c2c2c;
  border-radius: 100%;
  padding: 5px;
  box-shadow: 0px 0px 10px -2px #bfbfbf;
  cursor: pointer;
  z-index: 10000;
  width: 40px;
  height: 40px;
}

.arrow__next__icon{
  right: 20px;
}

.arrow__prev__icon{
  left: 20px;
}

.arrow__prev__icon > svg{
  margin-top: 2px;
}
.arrow__next__icon > svg{
  margin-top: 2px;
}
.dialog-arrow-wrapper{
  background-color: white;
  color: #2c2c2c;
  border-radius: 100%;
  padding: 8px 5px 5px;
  box-shadow: 0px 0px 10px -2px #bfbfbf;
  width: 45px;
  height: 45px;
  margin: 10px;
}
.disable-arrow{
  cursor: not-allowed !important;
  color: #8a8a8a !important;
}
.stand-back-icon{
  padding-left: 10px !important;
}
// .arrow-wrapper img{
//   margin-top: 3px;
// }
.timeline-wrapper{
  width: 60px;
  float: right;
  margin: 6px 25px;
  background: white;
  box-shadow: 0px 0px 10px -2px #bfbfbf;
  border-radius: 100%;
  height: 60px;
  padding: 10px;
}
.timeline-wrapper .timeline-icon{
  font-size: 40px;
}
.stand-detail-drawer-wrapper{
  // margin-top: 155px;
  margin: 190px auto 25px;
}
.stand-detail {
  max-height: calc(100vh - 128px);
  // height: calc(100vh - 128px);
  overflow-y: scroll;
  @media (min-width: $container-large-tablet) {
    min-height: 60vh;
  }
  @media (max-width: $container-large-tablet) {
    min-height: 30vh;
  }
  .MuiPaper-rounded {
    position: relative;
    z-index: 2;
    -moz-box-shadow: 0 0 0 2px $stands_background;
    -webkit-box-shadow: 0 0 0 2px $stands_background;
    box-shadow: 0 0 0 2px $stands_background;
  }
  &__head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__details {
    background: $stands_background;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.stand_details_statistic_icons > div{
  justify-content: space-evenly !important;
  margin: -10px auto 0px !important;
}

.iconsbox {
  // padding: 0 15px 15px;
  display: flex;
  flex-direction: column;
  // height: 140px;
  // justify-content: space-around;
  max-width: 375px;

  &__title {
    font-size: 12px !important;
    color: $lightcolor;
    margin-bottom: 12px !important;
  }
  &__lasttitle {
    font-size: 12px !important;
    color: $lightcolor;
    margin-bottom: 8px !important;
  }
  &__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      justify-content: space-between;
      margin: 0;
      @media only screen and (max-width: 768px) {
        width: 325px;
        margin: auto;
      }
    }
    &__item {
      padding: 8px 0px 0px !important;
      &__infoicon {
      }
      &__box {
        height: $btn-icon-w-lg;
        width: $btn-icon-w-lg;
        box-shadow: $stand-item-shadow !important;
        border-radius: $border-radius-lg !important;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        $color: $blue-dark;
        .boxnumber {
          font-size: $font-size-md;
          font-weight: 500 !important;
          color: $color;
          height: 15px;
        }
        p {
          line-height: 13px;
        }
        p:last-child {
          font-size: $font-size-xs;
          text-align: center;
          color: $gray;
        }
        svg {
          align-items: center;
          display: flex;
          margin: 0 auto;
          color: $darkblue;
          fill: $color;
        }
      }
    }
  }
  &__online {
    div {
      &.active {
        border: 2px solid #13c356;
      }
    }
    .member__info__wrapper{
      text-align: right;
      margin-right: 12px;
      cursor: pointer;
    }
    .membar__name{
      font-size: 14px;
      color: white;
      font-weight: 300;
    }
    .membar__title{
      font-size: 12px;
      // color: #BDBDBD;
      color: white;
      margin-top: 4px;
    }
    .image__wrapper {
      width: 40px;
      height: 40px;
      margin: 0 auto 0 10px;
    }
    .image__wrapper > img{
      border-radius: 100%;
    }
    ul {
      position: relative;
      z-index: $stand-item-z-index;
      li {
        float: right;
        padding: 10px;
        text-align: center;
        // width: 20%;
        width: 300px !important;
        margin-left: 0 !important;
        min-height: 80px;
        border: 3px solid #fff;
        box-shadow: $stand-item-shadow;
        @extend %bg-transition;
        
      @media only screen and (max-width: 768px) {
        width: 310px !important;
      }
      @media (min-width: 960px) and (max-width: 1072px) {
        width: 180px !important;
      }
      @media (min-width: 1073px) and (max-width: 1246px) {
        width: 220px !important;
      }
        &:hover {
          box-shadow: $stand-item-shadow-hover;
          background: $blue-hover;
        }
        div {
          margin: 0;
        }
        div :hover {
          opacity: 1;
          -webkit-animation: flash 1.5s;
          animation: flash 1.5s;
        }
        p {
          text-overflow: ellipsis;
          white-space: normal;
          cursor: pointer;
        }
      }
    }
.online__avatar{
  border: 3.5px solid #fff;
  border-radius: 100%;
}
    @-webkit-keyframes flash {
      0% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes flash {
      0% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }
  ul.member-container {
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;

    @include scroll(auto, 7px, $gray, none, 7px, $blue-dark, darken($blue-dark, 5%), 3px);

    li {
      display: inline-block;
      list-style: none;
      background: #FAFAFA;
      border: 4px solid #fff;
      width: 100%;
      border-radius: $border-radius-lg;
      display: flex;
      align-items: center;
      margin: 7px auto;
      &.active{
        background-color: $blue !important;
      }
      .MuiAvatar-circle {
        margin: 0 auto !important;
      }
      p {
        @include fontstyle(#fff, 12px, 300);
      }
    }
  }
}
.Tab-group {
  @media only screen and (max-width: 768px) {
    width: 325px !important;
  }
  @media (min-width: 960px) and (max-width: 1120px) {
    width: auto;
  }
  direction: ltr;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 10px;
  width: 300px;
  box-shadow: $shadow-down;
  border-radius: $border-radius-lg !important;
  button {
    flex-grow: 2;
    height: 35px;
    border-radius: $border-radius-lg !important;
    font-size: 14px;
    border-color: #fff !important;
    &.Mui-selected {
     background-color:$btn-default-bg !important ;
     color:$btn-default-font !important;
     &:hover{
      background-color:$btn-default-bg-hover !important;
     }
    }
  }
}
.membertab-title {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  svg {
    @include RLDir(margin, left, 5px);
  }
}

div#___reactour svg {
  opacity: 0.7 !important;
}

.pavilion_container {
  height: 550px;
}

.reactour__close {
  position: absolute !important;
  top: 15px !important;
  @include RLDir(null, right, 15px !important);
}

.reactour__helper nav {
  width: 100%;
}

.reactour__helper button {
  transform: rotate(-180deg);
}

.stand-info {
  header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea !important;
    button {
      .MuiTab-wrapper {
        flex-direction: row !important;
        svg {
          margin-bottom: 0px;
          margin-left: 6px;
        }
      }
    }
  }
  width: 100%;
  .MuiTab-textColorPrimary.Mui-selected {
    color: #2d323e;
    svg {
      color: #475974;
    }
  }
}

.stand-info-stand {
  header {
    color: #757575 !important;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea !important;
    border-top: 1px solid #eaeaea !important;
    .MuiTab-root {
      font-size: 16px;
      font-weight: 400;
      .MuiTab-wrapper {
        flex-direction: row !important;
        svg {
          margin-bottom: 0px;
          margin-left: 6px;
        }
      }
    }
    .MuiTab-textColorInherit.Mui-selected {
      color: #2d323e;
      font-weight: 500;
      svg {
        color: #475974;
      }
    }
    .MuiTabs-indicator {
      background-color: #475974 !important;
      height: 3px;
    }
  }
}

.stand-list {
  &__filter-list {
    width: 100%;
    padding: 14px 21px;
    border-top: none;
  }
  &__filter-panel {
    @include RLDir(border, left, 1px solid #eee);
  }
  &__menu-item {
    font-family: "IRANSansFa";
  }
}
.iconsbox-icons{
  width: 60px;
  height: 60px;
}
.iconsbox-icons svg{
  font-size: 22px;
}
.pavilion-frame{
  padding: 2px 3px;
  background-color: white;
  width: 285px;
  // height: 142px;
  border-radius: 30px;
  display: block;
  margin: 10px auto;
  box-shadow: 0px 15px 35px -20px #bdbdbd;
  position: relative;
  border-radius: 30px;
  margin: 25px 0;
  overflow: hidden;
  @media (min-width: 1073px) and (max-width: 1246px) {
    width: 218px;
  }
  @media (min-width: 960px) and (max-width: 1072px) {
    width: 184px;
  }
}

.pavilion-section{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.is-empty{
  background-color: #ddd;
  width: 100%;
  // height: 100%;
  border-radius: 26px;
  min-height: 100px;
  height: 157px;
  // position: relative;
}
.pavilion-frame-text{
  padding: 3px 15px 15px;
  font-size: 12px;
  background: white;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
}
.company-row {
  cursor: pointer;
}
.outlook-box{
  margin: auto;
  max-width: 300px;
  box-sizing: content-box;
}
.pavilion-info-wrapper{
  position: relative;
  overflow: hidden;
  border-radius: 26px;
  min-height: 100px;
}
.pavilion-info-wrapper .catalog-image{
  display: block;
  border-radius: 30px 30px 5px 5px;
  height: 157px;
  width: 100%;
  @media (min-width: 1073px) and (max-width: 1246px) {
    height: 123px;
  }
  @media (min-width: 960px) and (max-width: 1072px) {
    height: 104px;
  }
}
.pavilion-info-wrapper .video-image{
  display: block;
  border-radius: 30px 30px 5px 5px;
  height: 157px;
  width: 100%;
  @media (min-width: 1073px) and (max-width: 1246px) {
    height: 123px;
  }
  @media (min-width: 960px) and (max-width: 1072px) {
    height: 104px;
  }
}
.pavilion-info-wrapper .stand-eye-icon{
  display: block;
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 20px);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 6px;
  color: black;
  border-radius: 100%;
}
.with-text .stand-eye-icon{
  top: calc(50% - 50px);
}

// .video-image{
//   border-radius: 50px 50px 5px 5px;
//   width: 100%;
//   padding: 2px;
// }
.pavilion-info-icon{
  position: absolute;
  left: 0; 
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  width: 120px;
}
.viewer-icon{
  margin: 0 auto;
  width: 32px;
  height: 32px;
}
.imported-icon{
  width: 20px;
  height: 20px;
  margin: auto;
}
.company-row:hover {
  background-color: #eee;
}

.tabcontent-stand {
  height: calc(100vh - 124px) !important;
  background-color: white !important;
  position: relative;
}
.stickyheader {
  header {
    border-top: 1px solid #eee !important;
  }
  .MuiTab-wrapper {
    flex-direction: row !important;
    // .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
    svg {
      @include RLDir(margin, left, 6px !important);
      margin-bottom: 0px !important;
    }
  }
}

.image_container {
  padding: 100px;
  width: 75%;
  margin: 0 auto;
}

.stand-list-tabs {
  display: flex;
  flex-direction: row;
  padding-right: 260px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #9f9f9f;
  @media only screen and (max-width: 768px) {
    @include RLDir(padding, right, 0);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 900;
    padding: 10px;
    .MuiSvgIcon-root {
      display: none !important;
    }
  }
  padding-top: 10px;
  a {
    color: #2d323e !important;
  }
  &__item {
    width: 12%;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    span {
      padding-top: 3px;
      @include RLDir(padding, right, 5px);
    }
    &__selected {
      text-align: center;
      color: #2d323e;
      border-bottom: 3px solid #2d323e;
      padding-bottom: 13px;
      svg {
        fill: $darkblue;
      }
    }
  }
}

.stand-detail-infoBox {
  position: relative;
  .stand-detail-info__content__more {
    position: absolute;
    bottom: 0;
    @include RLDir(null, left, 0);
    height: 25px;
    font-size: 12px;
    text-align: center;
    box-shadow: 20px 0px 13px white;
    background-color: white;
    @include RLDir(padding, right, 5px);
    @include RLDir(padding, left, 8px);

    color: #57c0ed;
    cursor: pointer;
  }
}
.stand-detail-info {
  &__content {
    blockquote {
      padding: 0px !important;
      margin: 0px !important;
    }
  }
  &__content,
  &__content * {
    font-size: 12px !important;
    font-weight: 300 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    height: 70px; //42px;
    max-height: 70px; //42px;
    letter-spacing: normal;
    text-align: $right;
    overflow: hidden;
    color: $lightcolor;

    *:empty {
      display: none;
    }
  }
}

.membertable {
  .MuiCardHeader-avatar {
    margin-left: 8px !important;
  }
}
.memberlist-icon {
  display: flex;
  justify-content: space-evenly;

  button {
    color: $base-dark;
  }
}

//cooperation
.opportunity-list {
  position: absolute;
  width: 100%;
}
.opportunity-item__top {
  &__title-stand {
    font-size: 14px;
    font-weight: 500;
    color: #2d323e;
  }
  &__type-stand {
    padding: 8px 16px;
    background-color: #eaeaea;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #2d323e;
  }
}
.cooperation_top {
  flex-direction: row !important;
}
.stand-opportunity {
  display: flex;
  flex-direction: row;
  .opportunity-item__top__location {
    display: none;
    margin-left: 0px !important;
    @include RLDir(margin, right, 8px);
  }
}

.tellmodal {
  a,
  a:hover,
  a:active {
    width: 100%;
    display: flex;
    align-items: center;
    color: #9f9f9f !important;
    cursor: pointer;
  }
  .MuiListItem-root {
    direction: ltr !important;
    cursor: pointer;
  }
  .MuiListItemText-root {
    text-align: left !important;
  }
}

@media (max-width: 960px) {
  .stand-detail {
    &__head {
    }
    &__details {
      &__box {
        display: block;
        .iconsbox {
          width: 100% !important; //45% !important;
        }
      }
    }
  }
}
.phones_dropdown{
  background-color: white;
  text-align: left;
  position: absolute;
  margin-top: 135px;
  margin-right: 50px;
  z-index: 10;
  border-radius: 5px;
  color: black !important;
  box-shadow: 0 0 11px 2px #e6e6e6;
}
.phones_dropdown ul{
  list-style: none;
}
.phones_dropdown ul li{
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.phones_dropdown ul li a{
  color: black;
}
.phones_dropdown ul li:hover{
  background-color: #EBEBEB;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
@media (min-width: 960px) and (max-width: 1120px) {
  .iconsbox-icons{
    width: 45px;
    height: 45px;
  }
  .info-company__about__comm__icon svg{
    font-size: 20px;
  }
  .iconsbox-icons svg{
    font-size: 15px;
  }
  .iconsbox__icons__item__box {
    .boxnumber{
      font-size: 10px;
    }
    p{
      line-height: initial;
    }
    p:last-child{
      font-size: 8px;
    }
  }
}
@media (max-width: 600px) {
  .pavilion-frame-wrapper{
    padding: 0 20px;
    width: 100%;
  }
  .pavilion-frame{
    width: 100%;
  }
  // .stand-header header {
  //   .MuiTab-root {
  //     width: 50% !important;
  //   }
  // }
  .stand-detail {
    &__head {
    }
    &__details {
      padding-top: 14px;

      &__box {
        .iconsbox {
          width: 100% !important;
          &__icons {
            &__item {
              &__box {
                height: 64px;
                width: 64px;
              }
            }
          }
          &__title,
          &__lasttitle {
            font-size: 10px !important;
          }
        }
      }
    }
  }
  .stickyheader {
    height: calc(100% - 130px);
    min-height: calc(100% - 130px);
    max-height: calc(100% - 130px);
    header {
      border-top: 1px solid #eee !important;
      .MuiTab-root {
        width: 20% !important;
      }
    }
    .MuiTab-root {
      padding: 6px 2px;
    }
    .MuiTab-wrapper {
      font-size: 12px;
      font-weight: 500;
      svg {
        display: none;
      }
    }
  }
  .membertab-title {
    display: flex !important;
    width: 100%;
    margin: 0px !important;
  }

  .stand-info,
  .stand-info-stand {
    header {
      button {
        font-size: 14px;
        .MuiTab-root {
          padding: 6px 7px;
        }
        svg {
          display: none;
        }
      }
    }
  }
  //cooperation
  .opportunity-item__top {
    &__type-stand {
      font-size: 10px;
      padding: 8px 8px;
    }
  }
  .stand-opportunity {
    .opportunity-item__top__location {
      display: flex;
    }
  }
}

.mesh_hover{
  background-color: red;
}

@media (max-width: 420px) {
  .iconsbox {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .iconsbox {
    margin: auto;
    display: block;
  }
}
.div100{
  .stand-container{
   // min-height: calc(100vh - 130px);
  }
}

.MuiToolbar-regular {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 56px !important;
}
Canvas{
  height: 95vh;
}