.container_expo {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.expo-box {
  width: 50px;
  height: 50px;
  background-color: blue;
  margin: 5px;
}

.redux-toastr .toastr.rrt-success {
  background-color: #17aa4f !important;
}

.redux-toastr .toastr .rrt-left-container {
  width: 50px !important;
}
.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 50px !important;
}

.redux-toastr .toastr .rrt-middle-container {
  text-align: right !important;
  width: 75% !important;
  @include RLDir(margin,left,45px !important);
  padding: 22px 5px !important;
  font-size: 14px !important;
}
