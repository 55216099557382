	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: 500;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Black.eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Black.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Black.ttf') format('truetype');
	}
	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: 400;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Bold.eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
	}
	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: bold;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Bold.eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
	}
	@font-face {
	font-family: IRANSansFa;
	font-style: normal;
	font-weight: 300;
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Medium.eot');
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
	}
	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: normal;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Medium.eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_Medium.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
	}
	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: 300;//normal;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum).eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
		 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
	}
	@font-face {
		font-family: IRANSansFa;
		font-style: normal;
		font-weight: normal;
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum).eot');
		src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
			url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
			url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum).woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
			url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
	}
	@font-face {
	font-family: IRANSansFa;
	font-style: normal;
	font-weight: 200;
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Light.eot');
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
	}
	@font-face {
	font-family: IRANSansFa;
	font-style: normal;
	font-weight: 100;
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot');
	src: url('./static/fonts/iran-sans/persian-digits/fonts/eot/IRANSansWeb(FaNum)_UltraLight.eot?#iefix') format('embedded-opentype'),  /* IE6-8 */
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff2/IRANSansWeb(FaNum)_UltraLight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/woff/IRANSansWeb(FaNum)_UltraLight.woff') format('woff'),  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
	 url('./static/fonts/iran-sans/persian-digits/fonts/ttf/IRANSansWeb(FaNum)_UltraLight.ttf') format('truetype');
	}
	
	body{
		font-family: 'IRANSansFa' !important;
		h1, h2, h3, h4, h5, h6,input, textarea, p, div, span, label, button {
			font-family: 'IRANSansFa' !important;
		}
		&[dir="ltr"]{
			font-family: 'IRANSans' !important;
			h1, h2, h3, h4, h5, h6,input, textarea, p, div, span, label, button {
				font-family: 'IRANSans' !important;
			}
		}
		&.faNo{
			font-family: 'IRANSansFa' !important;
		}

	}
