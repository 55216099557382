/*---------------------- skeleton-----------------------------*/
.chat-item {
  .MuiCardHeader-root{
    width: 100%;
  }
  .chat-item-img {
    // width: 20%;
    float: $right;
    position: relative;
    &__status{
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #13c356;
      position: absolute;
      top: 60%;
      @include RLDir(null,left,0);
    }
    &__status-offline{
      background-color:  #f22e52;
    }
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .chat-item-content {
    width: 60%;
    float: $right;

    h1 {
      font-family: IRANSansFa;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: $right;
      color: #333333;
    }

    p {
      font-family: IRANSansFa;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      text-align: $right;
      color: #9f9f9f;
      word-break: break-word;
    mix-blend-mode: normal;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.send-msg-content-skeleton{
  border-radius: 15px;
  width: 190px;
  height: 38px;
}
.recieve-msg-skeleton{
    position: relative;
    width: 100%;
    padding-top: 5px;
    /* padding-left: 10px; */
    @include RLDir(padding,right,45px);
    justify-content: flex-end;
    display: flex;
}
.chat-date-skeleton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 24px;
}
.chat-container {
  padding: 24px;
  background-color: $lightsubtitle;
  &__empty-list{
    width: 100%;
  }
}
/*--------------------- contact list -------------------------*/
.selected-chat-item {
  background-color: #dcdcdc;
}
.chat-item {
  width: 100%;
  position: relative;
  height: 88px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
    .MuiCardHeader-root {
      padding: 0px  !important;
    }

  .chat-item-not-read-count {
    position: absolute;
    width: 19px;
    height: 19px;
    padding: 0 6px;
    background-color: #30a4e2;
    border-radius: 50%;
    color: #fff;
    @include RLDir(null,left,15px);
    top: 35px;
  }
}
.chatlist-cardheader{
  display: flex;
  flex-direction: column;
  &__title{
    @include fontstyle ($textcolor,14px,300);
  }
  &__subtitle{
    width: 169px;
    max-height: 40px;
    overflow: hidden;
    @include fontstyle ($gray,12px,300);
  }
}
.chat-item-date {
  float: $left;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  @include fontstyle ($darkbtntitle,10px,300);
}

/*--------------------- chat content -------------------------*/
.header-chatroom {
  flex-grow: 1;
  .AppBar {
    height: 72px;
    background-color: #fff !important;
    .MuiToolbar-regular {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    .title {
      flex-grow: 1;
      font-size: 16px;
      font-weight: bold;
      color: #2d323e;
    }

    .subTitle {
      flex-grow: 1;
      font-size: 10px;
      font-weight: 500;
      color: #9f9f9f;
    }

    .avatar {
      position: relative;
        &__status{
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #13c356;
          position: absolute;
          bottom: 5px;
          @include RLDir(null,right,32px);
      }
      &__status-offline{
        background-color:  #f22e52;
      }
      img{
         height: 40px;
          width: 40px;
          border-radius: 50%;
      }
    }
  }
}
.chat-list-container{
  background-color: #f8f8f8;
  position: relative;
  // height: 100%;
  &__box{
    position: relative;
    height: 100%;
    &__header{
      position:absolute;
      top: 0px;
      left:0px;
      right: 0px;
    }
  }
  &__chatlists{
    position: absolute;
    top: 74px;
    right: 0px;
    left: 0;
    overflow-y: scroll;
    height: calc(100vh - 198px) !important;
    // .infinite-scroll-component__outerdiv{
    //   height: 100% !important;
    // }
  }
  &__img{
    width: 100%;
    height: 100%;
    img{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.chattitle{
  position: relative;
  height: 100%;
  padding: 0px !important;
  &__title{
    @include fontstyle ($darkbtntitle,16px,bold);
  }
  &__subtitle{
    @include fontstyle ($gray,10px,300);
  }
  .avatar{
    padding:0px;
  }
  .MuiCardHeader-root {
    padding: 0px !important;
  }
  &__back{
    display: none;
  }
}

.send-msg {
  position: relative;
  width: 100%;
  text-align: $right;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #eee;
    margin-top: 12px;
    @include RLDir(margin,left,10px);
  }
}

.send-msg-content,.recieve-msg-content{
  margin-top: 10px;
  height: 38px;
  padding: 0px 16px;
  border-radius: 15px;
  line-height: 1.58;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1.58;
  display: inline-flex;
  flex-wrap: wrap;
  word-break: break-all;
}
.send-msg-content {
  background-color: $lightgray;
  @include fontstyle ($textcolor,12px,300)
}
.recieve-msg-content {
  background-color: $darkbtncolor;
  @include RLDir(padding,right,20px);
  @include fontstyle ($white,12px,300);
}

.recieve-msg {
  position: relative;
  width: 100%;
  text-align: $left;
  padding-top: 5px;
  @include RLDir(padding,right,45px);
  svg {
    font-size: 1rem !important;
  }
}
.send-message-time{
  display: flex;
  align-items: center;
  @include RLDir(padding,right,8px);
  @include fontstyle ($gray,10px,300);

  .read{
    svg{
      @include RLDir(margin,right,4px);
      vertical-align: middle;
    }
  }
}
.recieve-msg-time {
  display: flex;
  align-items: center;
  @include RLDir(padding,right,8px);
  @include fontstyle ($gray,10px,300);
  svg{
    position: absolute;
  }
}

.chat-date {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 24px;
  background-color: #ffffff;
  width: 180px;
  height: 32px;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  margin: 0 auto;
  border-radius: 5px;
  @include fontstyle ($darkbtntitle,14px,bold);
}

/*--------------------- send box ---------------------------*/
.send-box {
  height: 72px;
  background-color: #eee;
  @include RLDir("padding","right",10px);
  @include RLDir("padding","left",16px);
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  right: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 100% ;
  }

  input {
    flex: 0 1 100%;
    height: 40px;
    border: none;
    border-radius: 10px;
    @include RLDir(padding,right,40px);
  }

  .send-box-emoji {
    position: absolute;
    @include RLDir(null,right,15px);
    top: 23px;
    cursor: pointer;
  }

  .send-box-icon {
    width: 55px;
    height: 49px;
    background: #2ea5d9;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    @include RLDir(margin,right,16px);
    cursor: pointer;
    svg{
      fill:#fff;
      font-size: 2rem !important;
      transform: rotate(180deg);

    }
  }
}

.search-container input::placeholder {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: $right;
  color: #9f9f9f;
  padding-top: 4px;
}

.inputInput {
  width: 100%;
  height: 40px;
  padding-top: 2px;
  padding-bottom: 17px;
  @include RLDir(padding,right,40px);
  @include RLDir(padding,left,14px);
  border-radius: 25px;
  border: solid 1px #eaeaea;
  background-color: #ffffff;
}




@media (max-width: 960px){
  .header-chatroom {
    .button{
      display: none;
    }
  }
  .chat.sidepage{
    display: none;
    &.active{
      display: block;
    }
  }
  .chat-list-container{
    display: none;
    &.active{
      display: block;
    }
  }
  .chattitle{
    &__back{
      display: flex;//block;
    }
  }
  .livemeetingbtn{
    position: absolute;
    top: 120%;
    right: 0;
    left: 0;
    justify-content: center;
    display: flex;
  }
  .chat-container {
    padding: 16px;
  }
}