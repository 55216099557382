.modal {
  .MuiToolbar-regular {
    position: absolute !important;
    // top: 58px;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    z-index: 2;
  }
  &__content {
    position: absolute;
    // top: 56px;
    top: 0;
    width: 100%;
    display: flex;
    height: calc(100vh - 56px);
  }
  .MuiDialog-paperFullScreen {
    background-color: #ffffff !important;
    position: fixed;
    z-index: 9999999; //1300;
    inset: 0px;
    height: calc(100vh - 56px);
    bottom: 0px;
    top: 56px;
  }
  .MuiBackdrop-root {
    background-color: transparent;
  }
}

.bluemode {
  .MuiDialog-paperFullScreen {
    background-color: #2da5d9 !important;
  }
  .modal__bar {
    button .MuiIconButton-label,
    button svg {
      color: #fafafa !important;
    }
  }
  .MuiToolbar-regular {
    color: #fff;
  }
}
.lightmode {
  &.shadow-mode{
    .MuiDialog-paperFullScreen {
      box-shadow: inset 0px 7px 7px rgba(0,0,0,0.05);
    }
  }
  .MuiDialog-paperFullScreen {
    background-color: #ffffff !important;

  }
  &.gray_color{
    .MuiDialog-paperFullScreen {
      background-color: #fafafa !important;
  
    }
  }
  .modal__bar {
    .MuiIconButton-label {
      color: #707070;
    }
  }
  .modal__content * {
    color: #707070 !important;
  }
  .btn .MuiButton-label {
    border-bottom: 3px solid #707070 !important;
    padding-bottom: 8px !important;
  }
}
.header-bluemode {
  background-color: #2da5d9 !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  .register {
    // border: 1px solid #eee !important;
    background-color: #fff !important;
    span {
      color: #707070;
    }
  }
}

@media (max-width: 900px) {
  // .modal .MuiToolbar-regular {//saffarian changed
  //   right: 0 !important;
  //   direction: ltr;
  // }
  .loginform {
    padding: 0px;
  }
  .modal__content {
    height: 50% !important;
    top: 20% !important;
  }
}
