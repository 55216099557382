/*---------------------- Header --------------------*/
.profilebg{
  height: calc(100vh - 56px);
  background-color: $lightsubtitle;
}
.profile .profile-header {
  height: 128px;
  padding: 20px 16px 20px 28px;
  @include RLDir(padding,right,16px);
  @include RLDir(padding,left,28px);
  border-radius: 3px;
  background-color: #2d323e;
  width: 100%;
  &__content {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    justify-content: space-between;
    .avatarbox{
      position: relative;
      .MuiAvatar-root {
        width: 88px !important;
        height: 88px !important;
        border: 3px solid #2da5d9;
      }
      .editavatar{
        position: absolute !important;
        bottom: 0px;
        right: 0;
        color: $darkbtntitle;
        background-color: $white;
        border-radius: 50%;
        padding: 5px;
      }
    }
    // .MuiBox-root {
    //   width: 50%;
    // }
    // 
    .MuiButton-root {
      @include RLDir(margin,right,15px);
    }
  }
  &__info {
    @include RLDir(padding,right,22px);
  }
  &__title {
    line-height: 1.58;
    letter-spacing: normal;
    text-align: $right;
    @include fontstyle ($white,24px,500);
  }
  &__sub-title {
    line-height: 1.57;
    letter-spacing: normal;
    text-align: $right;
    @include fontstyle ($lightgray,14px,500);
  }
}
/*----------------------- Avatar -------------------*/
.delete-button {
  border:1px solid $lightgray !important;
  margin-bottom: 8px !important;
  padding: 5px !important;
  svg{
    color:$red;
  }
  @include RLDir(margin,right,-8px);
}
/*---------------------- Details -------------------*/
.editIconBtn{
  color: #fff !important;
}
.profile {
  padding: 25px;
  &__info {
    padding: 25px;
    background-color: white !important;
    height: auto !important;
    // min-height : 500px !important;
    flex-direction: column !important;
  }
  &__basic-info {
    height: 68px;//95px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #475974;
    width: 100%;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 0px 26px;
    justify-content: space-between;
    &__details {
      h2{
        @include fontstyle ($white,16px,bold)
      }
      div{
        @include fontstyle ($lightsubtitle,12px,300)
      }
    }
    .MuiBox-root button{
      @include RLDir(padding,left,0px);
    }
  }
  &__general-info {
    min-height: 202px;
    height: auto;
    padding: 24px ;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 12px 12px !important;
    border: solid 1px #eaeaea;
    background-color: #ffffff;
    width: 100%;
    color: #2d323e;
    .detailsbox{
      margin-bottom: 0px !important;
    }
    .MuiBox-root {
      margin-bottom: 20px;
      overflow-wrap: anywhere;
      // display: flex;
    }
    h3 {
      margin-top: 5px;
      display: contents;
      @include fontstyle ($darkbtntitle,14px,500);
      @include RLDir(padding,left,14px);
    }
    h4 {
      @include RLDir(margin,right,5px);
      @include fontstyle ($darkbtntitle,14px,300);
      display: inline;
    }
    &__icon {
      width: 40px;
      height: 40px;
      padding: 7px 0 10px 0;
      @include RLDir(padding,left,10px);
      @include RLDir(padding,right,8px);
      background-color: #475974;
      border-radius: 50%;
      @include RLDir(margin,left,16px);
      cursor: pointer;
      svg {
        fill: #fff;
      }
    }
  }
}
/*--------------------- Edit Info ------------------*/
/*-------------------- Edit Extra ------------------*/
.Profile_Detalis_Content{
  p{
    @include fontstyle ($darkbtntitle,12px,300);
  }
  .profile_selectbox{
    margin-bottom: 16px;
  }
  &_Items{
    .MuiFormControlLabel-root{
      margin: 8px 0 0 !important;
      padding: 0px !important;
      .MuiFormControlLabel-label {
        @include fontstyle ($darkgray,14px,bold);
      }
    }
    .MuiCheckbox-colorSecondary {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      @include RLDir(padding,left,5px);
      @include RLDir(padding,right,0px);
    }
  }
}



@media(max-width:600px){
/*---------------------- Details -------------------*/
  .profilebg{
    height: auto;
    background-color: $white;
  }
  .profile {
    padding: 0px;
      &__info{
        padding: 16px 12px;
      }
      &__basic-info {
        width:100%;
        justify-content: space-between;
        height: 74px;
        padding: 16px;
          &__details {
            width: auto;
            h2{
              font-size: 14px !important;
              font-weight: bold;
            }
            div{
              font-size: 12px !important;
            }
          }
          .MuiBox-root {
            width: auto;
          }
      }
      &__general-info{
        min-height: 155px;
        height: auto;
        padding: 16px;
          &__icon{
            width: 32px;
            height: 32px;
            padding: 0px;
            @include RLDir(margin,left,5px);
            margin-top: 8px;
            align-items: center;
            display: flex;
            justify-content: center;
          }
          .MuiBox-root{
            margin-bottom: 8px;
          }
          h3{
            @include RLDir(padding,left,5px);
            font-weight: bold !important;
          }
          h4{
            font-size: 12px !important;
          }
      }
  }
/*---------------------- Header --------------------*/
  .profile .profile-header {
    height: auto !important;
    padding: 35px 0px 27px !important;
      &__info {
        @include RLDir(padding,right,0);
      }
      &__title{
        @include fontstyle (null,18px,bold);
      }
      &__sub-title{
        text-align: center;
        @include fontstyle (null,12px,300);

      }
      &__content {
        flex-direction: column !important;
        .MuiBox-root {
          width: auto !important;
          justify-content: center;
        }
        .MuiButton-root {
          margin: 0px 5px !important;
        }
        .avatarbox {
          align-items: center;
          display: flex;
          margin: 20px 0px 25px;
        }
        .MuiAvatar-root {
          border: 3px solid #2da5d9;
          position: relative;
        }
        
      }
  }
  .profile .profile-header__content .MuiBox-root:first-of-type {
    width: auto !important;
    flex-direction:column-reverse ;
  }
/*-------------------- Edit Extra ------------------*/
.Profile_Detalis_Content{
  &_Title{
    font-size: 12px !important;
    margin-top:8px !important;
    &:last-of-type{
      margin-top: 12px !important;
    }
  }
  &_Items{
    width: 50% !important;
    max-width: 50% !important;
    flex-basis: 50% !important;
    padding: 0px;
      label{
        @include RLDir(margin,left,5px !important);
        padding: 6px 0px 2px !important;
        height: 25px !important;
        .PrivateSwitchBase-root-52 {
          padding: 5px;
        }
        span{
          font-size: 11px;
          font-weight: bold!important;

        }
    }
  }
}
}
  


// .showimg-editprofile{
//     width: 100%;
//     padding: 15px 0px 10px;
//     img{
//       height: 170px;
//       width: 170px;
//       margin:0 auto;
//     }
// }

// .header-save-btn {
//   border: solid 1px #30a4e2 !important;
//   background-color: #2da5d9 !important;
//   @include RLDir(margin,right,15px);
// }


// .tags{
//   display: contents;
//   span {
//   border: 1px solid #ddd;
//   display: inline-block;
//   color: #717171;
//   background: #fff;
//   -webkit-box-shadow: 0 1px 1px 0 rgba(180, 180, 180, 0.1);
//   box-shadow: 0 1px 1px 0 rgba(180, 180, 180, 0.1);
//   -webkit-transition: all 0.1s ease-in-out;
//   -moz-transition: all 0.1s ease-in-out;
//   -o-transition: all 0.1s ease-in-out;
//   -ms-transition: all 0.1s ease-in-out;
//   transition: all 0.1s ease-in-out;
//   border-radius: 2px;
//   margin: 0 5px 0 0;
//   padding: 5px 10px;
//   background-color: #eee;
//   font-size: 14px;
// }
// }
