.MuiAvatar-root {
  cursor: pointer;
}
.meeting-view {
  padding: 24px;
  background-color: $lightsubtitle;
}
.fontnumber {
  font-family: "IRANSansFa" !important;
}
.meeting {
  padding: 25px;
}
.meetingicon {
  width: 200px;
  display: flex;
  margin: 0 auto;
}
.createmeeting_simplestyle {
  @include fontstyle($darkbtntitle, 12px, 300);
}

/*------------------ chip style ------------------------*/
.pending,
.accepted,
.rejected {
  color: #fff !important;
  @include RLDir(margin, left, 25px);
}
.pending {
  color: #fff;
  background-color: #ec971f !important;
  border-color: #d58512; //#1976d2 !important;
}
.accepted {
  color: #fff;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}
.rejected {
  background-color: #d91a3c !important ; //rgb(220, 0, 78) !important;
}

.accepted svg {
  color: #fff !important;
}
.pending svg {
  color: #fff !important;
}
.rejected svg {
  color: #fff !important;
}

/*-----------------------------------------------------*/

// .meeting_link {
//   width: 100%;
//   text-align: center;
//   padding: 20px 0;
// }

// .meeting_link a {
//   font-family: "IRANSans" !important;
//   font-weight: 400;
//   text-align: center !important;
// }

/*------------------ first tab ----------------------*/
.meetingrow {
  height: 64px;
  cursor: pointer;
  .MuiTableCell-root {
    padding: 0px !important;
  }
  &__dateside {
    width: 70px;
    padding: 0px !important;
    .MuiCardHeader-root {
      padding: 0px;
    }
  }
  &__data {
    padding: 0px 16px !important;
    .MuiCardHeader-title {
      @include fontstyle($textcolor, 14px, 300);
    }
    .MuiCardHeader-subheader {
      @include fontstyle($gray, 10px, 300);
    }
  }
  &__date-time {
    .MuiCardHeader-content {
      span {
        @include fontstyle($gray, 12px, 200);
      }
    }
  }

  td {
    height: 64px !important;
  }
  td:hover {
    background-color: #f8f8f8;
  }
}

.meeting-empty {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  p {
    @include fontstyle($gray, 14px, bold);
  }
  svg {
    color: #9f9f9f;
    font-size: 8rem;
  }
}

/*------------------ sec tab ------------------------*/
.worksheetlist {
  height: calc(100vh - 208px) !important;
  overflow-y: scroll;
  scroll-behavior: smooth;
  .time {
    border-left: 1px solid $lightgray;
    border-right: 1px solid $lightgray;
    .MuiTypography-h5 {
      @include fontstyle($textcolor, 14px, 300);
    }
  }
  table tr {
    th {
      width: 12%;
    }
    td:first-child {
      width: 80%;
    }
    td:last-child {
      width: 8%;
    }
  }
}

.worksheet-item {
  height: 32px;
  width: 100%;
  position: relative;
  &__calender-data {
    float: $right;
    text-align: center;
    width: 100%;

    &__details {
      display: flex;
      justify-content: center;
      &__text {
        border-radius: 20px;
        padding: 5px;
        display: flex;
        &.disabled-box{
          background-color:$disabled-bg ;
          color: $disabled-color;
        }
        &:not(.disabled-box){
          background-color: $active-bg;
          color: $active-color;
        }
        .MuiAvatar-img{
          cursor: default;
        }
        p {
          padding-right: 30px;
          padding-left: 30px;
          display: flex;
          align-items: center;
          @include fontstyle(inherit, 14px, bold);
        }
      }
      &__text-event {
        background-color: $gray;
      }
    }
  }
  &__icon {
    float: $left;
    width: 40px;
    height: 40px;
    background: #f8f8f8;
    border-radius: 50%;
    @include RLDir(margin, left, 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #2d323e;
    }
    cursor: pointer;
    @media only screen and (max-width: 600px) {
      @include RLDir(margin, left, 15px);
    }
  }
  &__icon-locked {
    background-color: #475974;
    svg {
      fill: #fff;
    }
  }
  .MuiAvatar-root {
    width: 24px !important;
    height: 24px !important;
  }
  &__text {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: $right;
    color: #ffffff;
    @include RLDir(padding, right, 28px);
  }
}
/*----------------- modal state ---------------------*/
.invitation-box {
  color: #2d323e;
  padding: 15px 0px;

  select {
    height: 31px;
    margin-top: 6px;
    // margin-bottom: 27px;
    @include RLDir(margin, right, 26px);
    @include RLDir(margin, left, 16px);
    padding: 6px 22px 6px 22px;
    border-radius: 4px;
    border: solid 1px #eaeaea;
    background-color: #ffffff;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: $left;
    @include fontstyle($darkbtntitle, 12px, 300);
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    @include RLDir(margin, right, 8px);
  }
  .MuiAvatar-root {
    width: 40px;
    height: 40px;
    @include RLDir(margin, left, 8px);
  }
  &__heading {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 8px;
    // justify-content: space-evenly;

    &__name {
      letter-spacing: normal;
      text-align: $right;
      padding-top: 5px;
      @include fontstyle($bluebtn, 14px, bold);
      &__status-accepted {
        letter-spacing: normal;
        text-align: $right;
        @include fontstyle(#119845, 10px, 300);
      }
      &__status-rejected {
        letter-spacing: normal;
        text-align: $right;
        @include fontstyle(#d91a3c, 10px, 300);
      }
      &__status-invitor {
        letter-spacing: normal;
        text-align: $right;
        @include fontstyle($gray, 10px, 300);
      }
      &__status-pending {
        letter-spacing: normal;
        text-align: $right;
        @include fontstyle($gray, 10px, 300);
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 20px;
    font-size: 14px !important;
    font-weight: bold !important;
    .accptbtn {
      border: solid 1px #17aa4f !important;
      @include simplebtn(#17aa4f, #fafafa, 14px, bold, 36px, auto);
    }
    .rejectbtn {
      border: solid 1px #d91a3c !important;
      @include simplebtn(#d91a3c, #fafafa, 14px, bold, 36px, auto);
    }
    .MuiButton-outlinedPrimary {
      border: solid 1px #eaeaea !important;
      @include simplebtn($white, #2d323e, 14px, bold, 36px, auto);
    }
  }
  &__description {
    height: 100px;
    margin: 0px 0 15px;
    padding: 16px;
    border-radius: 10px;
    background-color: #eaeaea;
  }
  &__time {
    text-align: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-evenly;

    &__title {
      line-height: 1.58;
      letter-spacing: normal;
      text-align: $left;
      @include fontstyle($darkbtntitle, 12px, 300);
    }
    &__sub-title {
      line-height: 1.56;
      letter-spacing: normal;
      text-align: $left;
      @include fontstyle($darkbtntitle, 18px, 500);
    }
  }
  &__date {
    padding-left: 10px;
  }
}

.meeting-location {
  padding-right: 5px;
}

.meeting-error p {
  text-align: center;
  color: rgb(206, 17, 38);
  padding-bottom: 15px;
  font-size: 13px;
}
.meeting-avatar {
  position: relative;
  &__icon {
    position: absolute;
    top: 0;
    right: -8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    @include RLDir(padding, right, 4px);
    svg {
      fill: #fff;
    }
    &__send {
      background-color: #13c356;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      @include RLDir(padding, right, 4px);
    }
    &__recieve {
      background-color: #30a4e2;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      @include RLDir(padding, right, 4px);
    }
  }
}

/*----------------- modal add ---------------------*/
$add-meeting-width: 390px;
$add-meeting-small-width: 320px;
$add-meeting-avatar-width: 176px;
$add-meeting-avatar-small-width: 128px;
.add-meeting {
  width: $add-meeting-width;
  .MuiDrawer-paperAnchorRight {
    @include RLDir(null, right, 300px !important);
  }
  &__divider {
    height: 1px;
    background-color: #eaeaea;
    width: 100%;
    margin-top: 30px;
  }
  &__top-info {
    height: 265px;
    background-color: #2d323e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__close {
      width: 32px;
      height: 32px;
      background-color: #f8f8f8 !important;
      border-radius: 50% !important;
      position: absolute !important;
      @include RLDir(null, left, 16px);
      top: 16px;
    }
    &__title {
      line-height: 1.58;
      letter-spacing: normal;
      text-align: center;
      padding-top: 90px;
      @include fontstyle($white, 24px, 500);
      span {
        margin: 0 auto !important;
        background-color: #eaeaea;
        border: 1px solid #eaeaea;
      }
    }
    &__position {
      line-height: 1.56;
      letter-spacing: normal;
      text-align: center;
      @include fontstyle($lightgray, 16px, bold);
      span {
        margin: 0 auto !important;
        background-color: #eaeaea;
        border: 1px solid #eaeaea;
      }
    }
    &__avatar {
      text-align: center;
      margin-top: 20px;
      img,
      span {
        width:$add-meeting-avatar-width  ;
        height: $add-meeting-avatar-width ;
        border-radius: 50%;
        background-color: #eaeaea;
        border: 1px solid #eaeaea;
        margin: 0 auto;
      }
    }
  }
  &__desc {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__details-info {
      padding: $add-meeting-avatar-width * 0.5 20px 0px;

      &__social {
        margin: 20px 0px;
      }
      &__compnay {
        display: flex;
        align-items: center !important;
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid #eaeaea;

        &__compnay-img {
          width: 80px;
          height: 80px;
          background-color: #eee;
          border-radius: 50%;
          // margin-bottom: 20px;
          @include RLDir(margin, left, 10px);
          img {
            max-width: 100%;
            object-fit: contain;
            height: 100%;
          }
        }
        &__compnay-name {
          &__title {
            line-height: 1.56;
            letter-spacing: normal;
            text-align: $right;
            @include fontstyle($darkgray, 16px, bold);
          }
          &__subtitle {
            line-height: 1.57;
            letter-spacing: normal;
            text-align: $right;
            @include fontstyle($gray, 14px, 300);
          }
        }
      }
      ul {
        list-style: none;
        padding-top: 33px;

        li {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.57;
          letter-spacing: normal;
          text-align: $right;
          color: #2d323e;
          word-break: break-word;
          margin-bottom: 3px;
          span {
            font-size: 14px;
            font-weight: 300;
            @include RLDir(padding, right, 14px);
          }
        }
      }
      &__actions {
        text-align: center;
        span {
          margin: 10px auto;
        }
        .MuiButton-text {
          box-shadow: 0px 2px 3px silver;
        }
        &__meeting {
          height: 36px;
          border: solid 1px #475974;
          background-color: #475974 !important;
          color: #fff !important;
        }
        &__chat {
          padding: 7px 16px;
          @include RLDir(margin, right, 10px !important);
        }
      }
    }
  }
}

/*-------------------- footer ------------------------*/

.set-meeting {
  margin: 64px 0 0;
  padding: 14px 4px 16px 4px;
  border: solid 1px $white;
  background-color: $lightgray;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__text {
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 10px;
    @include fontstyle($darkgray, 12px, 300);
  }
  &__btn {
    width: 100%;
    text-align: center;
  }
  button {
    border: solid 1px #fafafa;
    background-color: $lightgray;
    width: auto;
    height: 36px;
    padding: 0 8px;
    @include fontstyle($darkgray, 14px, bold);
  }
}

.worksheetlist {
  .MuiCardHeader-root {
    padding: 0px !important;
  }
  .MuiTableCell-root {
    padding: 4px !important;
  }
  table tr {
    th {
      width: 16%;
    }
    td:first-child {
      width: 69%;
    }
    td:last-child {
      width: 15%;
    }
  }
}

@media (max-width: $container-large-mobile) {
  .meeting-view {
    padding: 24px 0px 0px;
  }
  /*--------- chip style ------------*/
  .pending,
  .accepted,
  .rejected {
    color: #fff !important;
    @include RLDir(margin, left, 8px);
  }
  /*----------- first tab -----------*/
  .meetingrow {
    &__date-time {
      .MuiCardHeader-content {
        span {
          font-size: 12px !important;
          font-weight: 200;
          color: #9f9f9f;
        }
      }
    }
    &__data {
      .MuiCardHeader-title {
        font-size: 14px !important;
        color: #333333;
      }
      .MuiCardHeader-subheader {
        font-size: 10px;
        color: #9f9f9f;
      }
    }
  }

  .worksheet-item {
    &__calender-data {
      &__details {
        &__text-event {
          display: flex;
          align-items: center;
        }
        &__text {
          p {
            font-size: 12px;
            padding: 0px 5px;
          }
         
        }
      }
    }
    &__icon {
      width: 32px;
      height: 32px;
      @include RLDir(margin, left, 5px);
    }
  }
  /*------- add meeting drawer ------*/
  .add-meeting {
    width: $add-meeting-small-width;
    &__top-info {
      height: 206px;
      &__title {
        padding-top: 70px;
        @include fontstyle(null, 18px, bold);
      }
      &__position {
        min-height: 21px;
        @include fontstyle(null, 14px, 200);
      }
      &__avatar {
        margin-top: 25px;
        img {
          width: $add-meeting-avatar-small-width;
          height: $add-meeting-avatar-small-width;
        }
      }
    }
    &__desc {
      height: calc(100vh - 206px);
      justify-content: space-between;
      &__details-info {
        padding: $add-meeting-avatar-small-width*0.5 20px 0px;
        &__actions {
          .MuiButton-label {
            font-size: $font-size-md;
            font-weight: 400;
            padding: 8px;
          }
        }
        &__social {
          margin: 10px 0px 12px;
        }
        &__compnay {
          position: relative;

          &__compnay-img {
            width: 40px;
            height: 40px;
          }
          &__compnay-name {
            &__title {
              @include fontstyle(null, $font-size-md, bold);
            }
            &__subtitle {
              @include fontstyle(null, $font-size-md, bold);
            }
          }
        }

        ul {
          li {
            @include fontstyle(null, $font-size-md, bold);
            span {
              @include fontstyle(null, $font-size-sm, 300);
            }
          }
        }
        &__actions {
          button {
            margin-top: 8px;
          }
          &__meeting {
          }
          &__chat {
          }
        }
      }
    }
  }
}
