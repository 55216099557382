.section1,
.section1 .fp-slide,
.section1 .fp-tableCell {
  height: auto;
}
// .fp-tableCell{
//     height: auto !important;
//     // overflow: scroll !important;
//     // scroll-behavior: smooth !important;
// }
.iScrollVerticalScrollbar {
  position: relative !important;
}
/*------------------------------ sections general -------------------------------------*/
@mixin sections_title($color) {
  color: $color;
  font-size: 24px !important;
  font-weight: 300 !important;
}
.btn-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

/*------------------------------ Header Menu -------------------------------------*/
.landing-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  position: fixed;
  width: 100%;
  z-index: 999999;
  .bluelogo {
    display: none;
  }
  .whitelogo {
    display: block;
  }
  &__logo {
    display: flex;
    align-items: center;
  }
  &__lang {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $lightcolor;
    .MuiButton-outlined {
      padding: 3px 3px 1px 4px !important;
    }
    .MuiButton-root {
      border: 1px solid $lightcolor;
      color: $lightcolor;
      height: 32px !important;
      min-width: 25px !important;
    }
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .headerinfo {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      color: $lightcolor;
      font-size: 16px;
      font-size: 400 !important;
      svg {
        @include RLDir(margin, right, 5px);
      }
      > * {
        color: inherit;
      }
      &.userprofile {
        flex-direction: row;
      }
    }
    .menu {
      display: none;
    }
  }
}
.header-sticky {
  display: flex;
  background-color: #ffff !important;
  color: #707070;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  justify-content: space-between;
  padding: 10px 40px;
  position: fixed;
  width: 100%;
  z-index: 999999;
  .bluelogo {
    display: block;
    width: 108px;
    height: 27px;
  }
  .whitelogo {
    display: none;
  }
  .landing-header__lang {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #707070;
    .MuiButton-root {
      border: 1px solid #707070;
      color: #707070;
    }
  }
  .landing-header__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .headerinfo,
    button svg {
      color: #707070;
    }
  }
  .register {
    border: 1px solid #ddd !important;
    background-color: #fff !important;
    span {
      color: #707070;
    }
  }
  .register,
  .login {
    box-shadow: none !important;
  }
}

//btn
%btn-swiper {
  width: 60px !important;
  height: 60px !important;
  background-size: contain;
  background-repeat: no-repeat;
}
.swiper-button-next {
  @extend %btn-swiper;
  left: -7px !important;
  background-image: url("./images/left.png");
  &::after {
    display: none !important;
  }
}

.swiper-button-prev {
  @extend %btn-swiper;
  right: -8px !important;
  background-image: url("./images/right.png");
  left: auto !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: none !important;
}

.register {
  min-width: 87px !important;
  @include RLDir(margin, right, 32px);
  @include RLDir(margin, left, 16px);
  border: solid 1px #ffff !important;
  @include btnstyle(transparent, #ffff, 14px, bold, 36px, 87px);
}
.login {
  min-width: 130px !important;
  border: solid 1px $darkbluebtn !important;
  @include btnstyle($darkbluebtn, $lightcolor, 14px, 500, 36px, 130px);
  // &:hover{
  //     border: solid 1px #57C0ED !important;
  //     background-color: #57C0ED !important;
  // }
}
.exhibition {
  @include btnstyle($lightcolor, $text-base-color, 14px, 500, 36px, 190px);
  // &:hover{
  //     background-color: #57C0ED !important;
  // }
}
.online-demo {
  @include btnstyle($brand-color, $lightcolor, 14px, 500, 36px, 190px);
}
.nomargin-right {
  @include RLDir(margin, right, 0px);
}
.spacetop_minimenu {
  top: 16px !important;
  ul li {
    font-family: "IRANSansFa";
    @include fontstyle(#707070, 12px, 400);
  }
}

/*------------------------------------ sec 1 -----------------------------------------*/
.firstsec {
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(335deg, #020024 0%, #4595d7 0%, #00d4ff 100%);
  position: relative;
  overflow: hidden;
  .imgarrow {
    position: absolute;

    top: 25px;
    bottom: 0;
    z-index: 2;
    width: 55%;
    height: 220%;
    background-image: url("./images/bluepath8.svg");
    background-size: cover;
    background-repeat: no-repeat;
    display: table;
    margin: auto;
    opacity: 0.65;
    &__left {
      left: 0;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    &__right {
      right: 0;
    }
  }
  div {
    display: flex;
  }
  &__right {
    height: 100% !important;
    display: flex;
    align-items: center;
    color: $lightcolor;
    position: relative;
    @include RLDir(padding, right, 16px);
    &__box {
      flex-direction: column !important;
      position: absolute;
      z-index: 3;
      padding-left: 25px;
    }
    &__title {
      @include fontstyle($lightcolor, 28px, 500);
    }
    &__desc {
      margin-bottom: 24px !important;
      margin-top: 16px !important;
      @include fontstyle($lightcolor, 16px, bold);
      line-height: 26px !important;
      font-weight: normal !important;
    }
  }
  &__left {
    height: 100% !important;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -4%;
    img {
      // transform: scale(1.5);
      // position: absolute;
    }
  }
  svg {
    position: absolute;
    bottom: 20px;
    right: 50%;
    left: 50%;
    color: $lightcolor;
    font-size: 48px;
    cursor: pointer;
    z-index: 999999;
  }
}
.homearrow {
  position: absolute;
  top: 8%;
  right: 29%;
  width: 110%;
  height: 84%;
}
/*------------------------------------ About -----------------------------------------*/
.about {
  // height: 493px;
  background-color: $lightcolor;
  align-items: center !important;
  padding: 50px 16px 65px !important;
  justify-content: center;
  &__container {
    background-color: $lightcolor;
  }
  &__box {
    display: flex;
    margin-bottom: 20px;
    &__title {
      @include sections_title($darkbtntitle);
    }
  }
  &__content {
    display: flex;
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px 24px;
      img {
        // height: 181px;
        width: 52%;
        border-radius: 8px;
      }
      .title {
        @include fontstyle(#5a5a5a, 24px, 500);
        margin-bottom: 8px !important;
      }
      .desc {
        @include fontstyle(#b1aaaa, 16px, 300);
        padding: 0 50px !important;
      }
    }
  }
}
/*------------------------------------ sec 3 -----------------------------------------*/
.protocol__container {
  background-color: #2b3647;
  position: relative;
  padding: 52px 0;
  font-size: 14px;
  &__path {
    position: absolute;
    top: 0;
    width: 32%;
    height: 66%;
    left: 0;
    background-image: url("./images/darkpath.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__title {
    margin-bottom: 20px !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      @include sections_title($lightcolor);
      font-size: 1.71em !important;
    }
    img {
      transform: rotate(-35deg);
      @include RLDir("margin", "left", 8px);
    }
  }
  &__content {
    margin-top: 52px !important;
    position: relative;
    // .swiper-wrapper {
    //   padding: 30px 0;
    // }
    &__box {
      padding: 0px 30px;
      color: $lightcolor;
      z-index: 2;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      &__image {
        height: 85px;
        text-align: center;
        img {
          height: 100%;
        }
      }
      text-align: center;
      .title {
        padding-top: 30px;
        @include fontstyle($lightcolor, 24px, bold);
      }
      .desc {
        line-height: 1.625;
        padding-top: 8px;
        @include fontstyle($lightcolor, 16px, 300);
      }
    }
    &__image-between {
      position: absolute;
      top: 0;
      left: 0;
      right: 30px;
      height: 100%;
      z-index: -1;
      width: 100%;
      margin: auto;
      padding: 5px 12%;
      display: block;
      &:before {
        content: "";
        background-image: url("./images/line.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100px;
        display: block;
      }

      @media (max-width: $container-large-tablet) {
        left: unset;
        right: 100px;
        height: 100%;
        width: 60px;
        padding: 70px 8px;
        &:before {
          background-image: url("./images/line_res.svg");
          width: 50px;
          height: 100%;
        }
      }
      
      @media (max-width: $container-large-mobile) {
        right: 8%;
      }
    }
  }
  .MuiButton-outlined {
    border: 1px solid $lightcolor !important;
    cursor: pointer;
  }
  .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child) {
    border-left-color: $lightcolor !important;
  }
  .MuiButtonGroup-grouped {
    font-size: 1em;
  }
  .MuiButton-label {
    @include fontstyle($lightcolor, 18px, bold);
  }
  .active {
    background-color: #2da5d9 !important;
  }

  &__footer {
    p {
      @include fontstyle($lightcolor, 18px, 400);
    }
    svg {
      color: #52b5df;
    }
    padding-top: 52px;
    border-bottom: 1px solid #6ec0e4;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
/*------------------------------------ sec 4 -----------------------------------------*/
.pavilion {
  height: 738px;
  // height: 682px;
  background-color: $lightcolor;
  align-items: center !important;
  flex-direction: column;
  padding: 106px 16px 30px !important;
  // padding: 70px 16px 55px !important;
  &__container {
    background-color: $lightcolor;
    position: relative;
  }
  &__top {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      p {
        @include sections_title($darkbtntitle);
      }
      img {
        position: absolute;
        top: -29%;
        left: -13%;
      }
    }
    &__more {
      display: flex;
      flex-direction: row;
      @include fontstyle(#2d323e, 16px, 300);
    }
  }
  &__slider {
    // display: flex;
    width: 100%;
    margin-top: 20px;
    // .swiper-wrapper {
    //   .MuiPaper-root {
    //     width: 336px !important;
    //   }
    //   padding: 0px 0px 20px !important;
    // }
  }
  .backimg {
    position: absolute;
    top: 0;
    height: 100%;
    &__left {
      left: 0;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    &__right {
      right: 0;
    }
  }
}

//Card
$btns:
  "attention" ,
  "default"   ,
  "action"    ,
  "success"   ,
  "disabled" ;

@each $name  in $btns {
  .tags-backcolor-#{$name} {
    @extend  %lbl-#{$name};
    box-shadow: $shadow-down;
  }
}

.card {
  display: flex;
}
.pavilioncard {
  width: 340px;
  height: 489px;
  margin: 0 auto;
  // height: 534px;
  position: relative;
  box-shadow: 0 0 0 0px rgba(63, 63, 68, 0.5), 0 0px 20px 0 rgba(63, 63, 68, 0.15) !important;
  .pavilioncard__media__disable {
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 50%;
    z-index: 1;
  }
  .MuiCardMedia-root {
    // background-size: contain !important;
  }
  &__tags {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 12px;
    position: absolute;
    width: 100%;
    top: 8px;
    z-index: 2;
    &__right {
      width: auto;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1;
      background-color: $lightcolor;
      border-radius: 8px;
      padding: 0px 8px;
      &__date {
        @include fontstyle(#2d323e, 18px, 500);
      }
      &__month {
        @include fontstyle(#2d323e, 9px, 500);
      }
    }
    &__left {
      font-size: $font-size-sm;
      display: flex;
      align-items: center;
      height: 37px;
      padding: 0px 16px;
      border-radius: 8px;
      svg {
        @include RLDir(margin, left, 5px);
      }
    }
  }
  &__media {
    height: 219px;
    width: 100%;
    object-fit: contain;
  }
  &__content {
    height: 270px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding: 32px 16px 16px !important;
    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      @include fontstyle(#2d323e, 18px, 400);
    }
    &__subtitle {
      @include fontstyle(#9f9f9f, 12px, 300);
      margin: 8px 0px !important;
      align-items: center;
      display: flex;
      svg {
        height: 15px;
      }
    }
    &__desc {
      @include fontstyle(#151d41, 14px, 300);
      height: 41px;
    }
    &__tags {
      @include fontstyle(#585858, 12px, 300);
      margin-top: 12px !important;
      height: 32px;
      overflow: hidden;
      .MuiChip-outlined {
        margin: 0px 4px !important;
        span {
          font-size: 11px;
        }
      }
    }
    button {
     
      &.btn-default{
        @include btnStyleByVal('default', 14px, bold, 36px, 100%);
      }
      &.btn-action{        
        @include btnStyleByVal('action', 14px, bold, 36px, 100%);
      }
      border: 1px solid $darkbluebtn;

      &.Mui-disabled {
        @include btnStyleByVal('disabled', 14px, bold, 36px, 100%);
      }
    }
    .MuiButton-contained.Mui-disabled {
      border: 1px solid #d2d1d1 !important;
    }
    .circle {
      width: 64px;
      height: 64px;
      background-color: $lightcolor;
      position: absolute;
      top: -32px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
        border: 1px solid #9f9f9f;
      }
    }
  }
}

/*------------------------------------ appdesc -----------------------------------------*/
.appdesc {
  height: 512px;
  background-color: #2da5d9;
  &__container {
    background-color: #2da5d9;
    position: relative;
    .arrowleft {
      position: absolute;
      height: 90%;
      top: 5%;
      left: 0;
    }
  }
  &__right {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @include RLDir(margin, left, 60px);
    }
    .video-payer {
      margin-bottom: $margin-base;
      position: relative;
      z-index: 1;

      @media (min-width: $container-large-tablet) {
        max-width: 90%;
      }
      @media (max-width: $container-large-tablet) {
        max-width: 100%;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $container-large-tablet) {
      flex-direction: column;
    }
    &__box {
      position: absolute;
      z-index: 3;
    }
    &__title {
      width: 385px;
      @include fontstyle($lightcolor, 40px, 500);
    }
    &__desc {
      width: 427px;
      @include fontstyle($lightcolor, 16px, 300);
      margin: 15px 0px 20px !important;
    }
    button {
      @include btnstyle($lightcolor, $darkbtntitle, 14px, bold, 36px, auto);
    }
  }
}

/*--------------------------------- Statistics ----------------------------------------*/
.statistics {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px 50px;
  justify-content: space-between;
  &__container {
    background-color: $lightcolor;
    .bluearrow {
      position: absolute;
      width: 15%;
      right: 56%;
      top: -1px;
    }
    .lightarrow {
      position: absolute;
      top: 100%;
      width: 16%;
      left: 64%;
    }
  }
  &__title {
    position: relative;
    display: flex;
    // margin-top: 80px;
    p {
      @include sections_title($darkbtntitle);
    }
    img {
      position: absolute;
      left: 90%;
      bottom: -30%;
    }
  }
  &__content {
    display: flex;
    align-items: center;
    max-width: 60% !important;
    justify-content: space-between;
    &__box {
      width: 132px;
      height: 132px;
      background-color: #ffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 5px 10px #f1f1f1;
      .border {
        width: 96px;
        height: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid #2da5d9;
        border-radius: 50%;
      }
      .number {
        @include fontstyle(#151d41, 21px, 500);
        letter-spacing: 0.04em !important;
      }
      .title {
        @include fontstyle(#151d41, 10px, 500);
      }
    }
  }
}

/*---------------------------------- Customers -----------------------------------------*/
.customers {
  height: 222px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 16px 55px;
  justify-content: space-between;
  &__container {
    background-color: #ffffff;
  }
  &__title {
    position: relative;
    display: flex;
    p {
      @include sections_title($darkbtntitle);
    }
    img {
      position: absolute;
      right: 93%;
      top: 23%;
    }
  }
  &__content {
    display: flex !important;
    align-items: baseline;
    justify-content: center;
    img {
      max-height: 32px;
      max-width: 130px;
      margin: 0px 16px;
      // filter: grayscale(100%) opacity(0.5);
      // transition: filter 0.3s ease-in-out;
      // &:hover {
      //   filter: grayscale(0) opacity(1);
      // }
      // &:hover {
      //   filter: grayscale(100%) opacity(0.5);
      // }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

/*----------------------------------- Footer -----------------------------------------*/
.footer {
  height: 150px;
  background-color: $lightcolor;
  padding: 30px 16px;
  align-items: center;
  &__container {
    background-color: $lightcolor;
    margin-top: auto;
  }
  .logo {
    height: 40px;
    width: 84px;
    filter: grayscale(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    @include fontstyle(#9f9f9f, 10px, 300);
    img {
      height: 21px;
    }
  }
  .icons {
    display: flex;
    justify-content: center;
    color: #585858;
    align-items: center;
    cursor: pointer;
    svg {
      font-size: 21px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    p {
      @include fontstyle(#9f9f9f, 10px, 300);
    }
    ul li {
      display: inline-block;
      list-style: none;
      margin: 0px 5px;
      a {
        @include fontstyle(#585858, 12px, 300);
      }
    }
  }
  .footer-left-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: $container-large-tablet) {
      // justify-content: space-around;
      justify-content: space-evenly;
      margin-top: 10px;
    }
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      width: auto;
      margin: 0;
      a,
      .samandehi {
        width: 80px;
        height: auto;
        filter: grayscale(1);
        transition: filter 1s;
        border: 1px solid #fafafa;
        &:hover {
          filter: grayscale(0);
          display: inline-block;
        }
      }
    }
  }
}

/*------------------------------------ login form  -----------------------------------------*/
.loginform {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-around;

  .loginBoxs,
  .registerbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }
  .loginBoxs:hover {
    background-color: #57c0ed;
  }
  .registerbox:hover {
    background-color: #eaeaea;
  }
  // .loginBoxs:hover{
  //     background-color:#57C0ED ;
  // }

  .btn-text {
    display: flex;
    flex-direction: row;
    width: 315px;
    justify-content: space-between;
    color: $lightcolor;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      .login_btn {
        @include fontstyle($lightcolor, 24px, 300);
      }
      .login_btn_bold {
        @include fontstyle($lightcolor, 24px, 500);
      }
      .register_btn {
        @include fontstyle(#707070, 24px, 300);
      }
      .register_btn_bold {
        @include fontstyle(#707070, 24px, 500);
      }
      svg {
        margin-left: 5px;
        height: 27px;
      }
    }
  }
  .btn {
    width: 315px;
    height: 62px;
    .MuiButton-label {
      border-bottom: 3px solid $lightcolor;
      padding-bottom: 8px !important;
    }
  }
}

///////////////////////////////////////////// RESPONSIVE 600px ///////////////////////////////////////////////////////////
@media (min-width: 600px) {
  // .swiper-slide {
  //   margin-left: 10px !important;
  // }
  // .swiper-wrapper {
  //   margin-right: 40px;
  // }
  .fullpage {
    height: 100% !important;
  }
  .protocol__container {
    &__content {
      &__box {
        position: static;
        &__arrow {
          position: absolute;
          top: 0;
          height: 100px;
          left: 0;
          right: 0;
          width: 70%;
          z-index: -1;
          display: flex;
          margin: 0 auto;
          align-items: center;
          justify-content: flex-start;
          &:before {
            content: "";
            width: 2.28em;
            height: 2.28em;
            background-image: url("./images/minipath.svg");
            background-repeat: no-repeat;
          }
          &[attr-index="0"] {
            &:before {
              transform: rotate(-56deg);
              animation: effect-arrow-1 6s linear infinite;
            }
          }
          &[attr-index="1"] {
            &:before {
              right: 30px;
              top: calc(100% + 18px);
              transform: rotate(-90deg);
              animation: effect-arrow-2 1.43s linear infinite 1s;
              display: none;
            }
          }
          &[attr-index="2"] {
            &:before {
              right: 70px;
              top: calc(100% + 18px);
              transform: rotate(-90deg);
              animation: effect-arrow-2 3s linear infinite 2s;
              display: none;
              transform-origin: 50% 50%;
            }
          }
          &[attr-index="3"] {
            &:before {
              display: none;
            }
          }
          @keyframes effect-arrow-1 {
            0% {
              transform: translate(10px, -22px) rotate(40deg);
            }
            20% {
              transform: translate(-365%, -43px) rotate(5deg);
            }
            40% {
              transform: translate(-859%, 46%) rotate(-20deg);
            }
            50% {
              transform: translate(-1300%, 160%) rotate(4deg);
            }
            60% {
              transform: translate(-1900%, 15px) rotate(22deg);
            }
            80% {
              transform: translate(-2300%, -93%) rotate(9deg);
            }
            90% {
              transform: translate(-2600%, -93%) rotate(-17deg);
            }
            100% {
              transform: translate(-2915%, 17%) rotate(-17deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  //---------------------------------
  .loginregister_drawer .Drawer_Header {
    height: 56px !important;
  }
  //------------- Header -------------
  .landing-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 16px;
    .register,
    .login {
      display: none;
    }
    .menu {
      display: block;
      padding: 0px;
      svg {
        @include RLDir(margin, right, 8px);
        color: #fff;
      }
    }
    .landing-header__action p {
      display: none;
    }
  }

  //------------- FirstSection -------------
  .firstsec {
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    .imgarrow {
      display: none;
    }
    div {
      position: relative;
      justify-content: center;
    }
    &__container {
      .MuiGrid-container {
        flex-wrap: wrap-reverse !important;
      }
    }

    &__right {
      width: 100%;
      height: auto !important;
      justify-content: center;
      @include RLDir(padding, right, 0px);
      // position: absolute;
      bottom: 0;
      &__box {
        width: 91%;
        // height: 100%;
        flex-direction: column !important;
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__title {
        @include fontstyle($lightcolor, 18px, 400);
      }
      &__desc {
        @include fontstyle($lightcolor, 14px, 300);
        margin-bottom: 16px !important;
        height: 146px;
        overflow: hidden;
      }
      &__btnbox {
        position: relative !important;
      }
    }
    &__left {
      height: auto !important;
      width: 100%;
      top: 0;
      left: 0;
    }
    svg {
      display: none;
    }
  }

  .imgarrow {
    display: none;
  }
  .homearrow {
    display: none;
  }
  //----------- PavilionsSlider -------------
  .pavilion {
    padding: 50px 0px 30px !important;
    height: auto;
    &__container {
      height: auto;
    }
    &__top__title p {
      @include fontstyle(#464646, 16px, 300);
    }
    &__slider {
      // .swiper-container .swiper-wrapper .MuiCard-root {
      //   margin-left: 8px !important;
      // }
    }
  }

  .pavilioncard {
    width: 253px;
    height: 380px;
    &__tags {
      &__right {
        height: 30px;
        font-size: 13px;
        &__date {
          @include fontstyle(#2d323e, 13px, 500);
        }
        &__month {
          @include fontstyle(#2d323e, 6px, 500);
        }
      }
      &__left {
       font-size: $font-size-xs;
        height: 31px;
        svg {
          font-size: 15px;
        }
      }
    }
    &__media {
      height: 165px;
    }
    &__content {
      height: 215px;
      width: 100%;
      padding: 32px 16px 8px !important;
      .pavilioncard__content .circle {
        width: 48px;
        height: 48px;
      }
      &__title {
        @include fontstyle(#2d323e, 13px, 400);
      }
      &__subtitle {
        @include fontstyle(#9f9f9f, 9px, 300);
        margin: 5px 0px !important;
      }
      &__desc {
        @include fontstyle(#151d41, 12px, 300);
      }
      &__tags {
        @include fontstyle(#585858, 7px, 300);
        margin-top: 5px !important;
      }
    }
  }
  // .pavilion__slider .swiper-wrapper .MuiPaper-root {
  //   width: 253px !important;
  // }

  //--------------- Protocol ---------------
  .protocol__container {
    font-size: 10.5px;
    &__content {
      &__box {
        margin-bottom: 60px !important;
        text-align: $right;
        &__image {
          @include RLDir("padding", "left", 30px);
        }
        .title {
          padding-top: 0;
          @include fontstyle(null, 16px, bold);
        }
        .desc {
          @include fontstyle(null, 12px, 300);
        }
        &[attr-index="3"] {
          margin-bottom: 0px !important;
        }
        &__arrow {
          top: 60%;
          position: absolute;
          right: 30px;
          left: calc(100% - 30px);
          width: 60px;
          display: block;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            content: "";
            width: 2.28em;
            height: 2.28em;
            background-image: url("./images/minipath.svg");
            background-repeat: no-repeat;
          }
          &[attr-index="0"] {
            &:before {
              transform: rotate(-56deg);
              animation: effect-arrow-1 6s linear infinite;
            }
          }
          &[attr-index="1"] {
            &:before {
              right: 30px;
              top: calc(100% + 18px);
              transform: rotate(-90deg);
              animation: effect-arrow-2 1.43s linear infinite 1s;
              display: none;
            }
          }
          &[attr-index="2"] {
            &:before {
              right: 70px;
              top: calc(100% + 18px);
              transform: rotate(-90deg);
              animation: effect-arrow-2 0.3s linear infinite 2s;
              display: none;
              transform-origin: 50% 50%;
            }
          }
          &[attr-index="3"] {
            &:before {
              display: none;
            }
          }
          @keyframes effect-arrow-1 {
            0% {
              transform: translate(0, 0px) rotate(0);
            }
            20% {
              transform: translate(-28px, 60px) rotate(-90deg);
            }
            40% {
              transform: translate(20px, 177px) rotate(-78deg);
            }
            50% {
              transform: translate(4px, 260px) rotate(-35deg);
            }
            60% {
              transform: translate(4px, 260px) rotate(-35deg);
            }
            80% {
              transform: translate(-23px, 335px) rotate(-90deg);
            }
            100% {
              transform: translate(0px, 410px) rotate(-85deg);
            }
          }
        }
      }
    }
    .MuiButton-label {
      @include fontstyle(null, 14px, 300);
    }
    &__footer {
      padding-top: 0px !important;
      p {
        @include fontstyle(null, 12px, bold);
      }
    }
  }
  //---------------- About ----------------
  .about {
    height: auto;
    padding: 50px 0px 65px !important;
    .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      padding: 20px 12px;
    }
    &__box {
      &__title {
        @include fontstyle(#464646, 18px, 300);
      }
    }
    &__content {
      &__box {
        .title {
          @include fontstyle(#5a5a5a, 16px, 500);
        }
        .desc {
          @include fontstyle(#b1aaaa, 14px, 300);
          text-align: center;
        }
      }
    }
  }
  //----------- AppDescription ------------
  .appdesc {
    height: 100%;

    &__container {
      height: 593px;
      align-items: center;
      display: flex;
      .arrowleft {
        display: none;
      }
    }
    &__right {
      align-items: center;
      justify-content: center;
      img {
        margin: 0px;
        height: 283px;
      }
    }
    &__left {
      &__box {
        position: relative;
      }
      &__title {
        @include fontstyle($lightcolor, 22px, 500);
        text-align: center;
        width: auto;
      }
      &__desc {
        @include fontstyle($lightcolor, 12px, 300);
        width: auto;
      }
      .MuiButton-root {
        padding: 0px 16px;
        // width: 107px;
        height: 39px;
      }
    }
  }
  //--------------- Statistics -------------
  .statistics {
    height: 100%;
    padding: 24px 0px 32px;
    &__container {
      .bluearrow,
      .lightarrow {
        display: none;
      }
    }
    &__title {
      margin-top: 0px;
      p {
        @include fontstyle(#464646, 18px, 300);
      }
    }
    &__content {
      max-width: 70% !important;
      &__box {
        width: 76px;
        height: 75px;
      }
      .border {
        width: 55px;
        height: 55px;
        .number {
          @include fontstyle(#151d41, 14px, 400);
        }
        .title {
          @include fontstyle(#151d41, 6px, 500);
        }
      }
    }
  }
  //-------------- Customers ---------------
  .customers {
    height: 100%;
    padding: 24px 0px 32px;

    &__container {
      height: 188px;
      & > div {
        height: 100% !important;
      }
    }
    &__title {
      p {
        @include fontstyle(#464646, 18px, 300);
      }
    }
    &__content {
      width: 100%;
      height: 58px;
      overflow-y: hidden;
      display: block;
      text-align: center;
    }
  }
  //---------------- Footer ----------------
  .footer {
    height: auto;
    padding: 30px 0px;
    .logo {
      align-items: center;
    }
    .icons {
      margin: 8px 0px;
      cursor: pointer;
    }
    .menu {
      align-items: center;
      ul {
        margin-bottom: 10px;
        li a {
          @include fontstyle(#585858, 12px, 300);
        }
      }
    }
  }
  //--------------- login form --------------
  // .loginform .btn-text span .MuiTypography-body1 {
  //   @include fontstyle($lightcolor, 18px, 300);
  // }
}

@media (max-width: 900px) {
  #fullpage {
    height: 100%;
  }
  .loginBoxs,
  .registerbox {
    width: 100% !important;
    height: auto !important;
  }
}
@media (max-width: 600px) {
  .statistics__content {
    max-width: 97% !important;
  }
}
@media (max-width: 360px) {
  .statistics {
    height: 100%;
    padding: 24px 0px 32px;
    &__container {
      // height: 186px;
      .bluearrow,
      .lightarrow {
        display: none;
      }
    }

    &__content {
      max-width: 100% !important;
      &__box {
        width: 65px;
        height: 65px;
      }
      .border {
        width: 52px;
        height: 52px;
      }
    }
  }
  .customers {
    &__content {
      img {
        margin: 0px 10px;
      }
    }
  }
}

.lang_section {
  svg {
    display: none;
  }

  button {
    height: 32px !important;
  }

  .MuiListItem-gutters {
    padding: 4px 10px;
  }
}
//------------------pavilon in Login------------------------
.pavilion_header_container {
  //background-color: #a623a6;
  max-width: 100%;
  width: 100%;
  @media (max-width: 1000px) {
    margin-top: 10%;
  }
  @media (max-width: 900px) {
    //  margin-top: -10%;
    margin-top: 0;
  }
  @media (max-width: 600px) {
    //  margin-top: -10%;
    margin-top: -15%;
  }
  .pavilion__container {
    // background: transparent;
    padding-bottom: 20px !important;
    min-height: 100%;
    @media (max-width: 600px) {
      padding: 0;
      //  height: calc(100vh - 56px);
    }
    .pavilion {
      // background: transparent;
      @media (max-width: 991px) {
        padding: 0 !important;
      }
      .pavilion__top__title {
        margin: 15px 5px;
        img {
          //display: none;
        }
      }
    }
  }
  .swiper-container:not(.swiper-container-rtl) {
    .swiper-button-next,
    .swiper-button-prev {
      right: 10px;
      left: auto;
    }
    .swiper-button-next {
      &::after {
        display: none !important;
      }
    }
  }
  .swiper-wrapper * {
    .pavilioncard__content button * {
      color: #fff !important;
    }
    .pavilioncard__tags__left {
      color: #fff !important;
      & * {
        color: #fff !important;
      }
    }
  }
}
.loading-landing {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $brand-color;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
}
