$lightcolor:#FAFAFA;
 
 
 .mainpage , .rightside{
    position: relative;
    height: calc(100vh - 126px) !important;
    width: 100%;
    display: flex;
 }
 .uploadbox{
  display: flex;
  flex-direction: column;
 }
 
 .opportunitypage{
   width: 100%;
   height: calc(100vh - 130px) !important;
   position: relative;
   &__content{
    height: calc(100vh - 182px) !important;
    overflow-y: scroll;
    position: absolute;
    top:0;
    right: 0;
    left: 0;
    &__table{
      height: 72px !important;
      overflow: hidden;
      border-radius: 0;
      .MuiTableRow-root {
        height: 72px;
      }
    }
   }
 }
   
/*------------------------------ buttons ---------------------------------*/

.canclebtn{
  @include RLDir(margin,right,10px);
  span{
    color: #D91A3C;
    @include fontstyle (null,14px,bold)
  }
}
.allsuggest{
  @include btnstyle($darkbluebtn,$lightcolor,14px,bold,36px,310px)
}
.disabledbtn{
  @include btnstyle($disablebtn,$lightcolor,14px,bold,36px,310px)
}
.mysuggest{
  @include btnstyle($greenbtn,$lightcolor,14px,bold,36px,310px)

}
.standsuggest{
  @include btnstyle($darkbtncolor,$lightcolor,14px,bold,36px,310px)
}
.standsuggestdisable{
  background-color: #859ab8 !important;
}

/*------------------------------- opportunity-item ---------------------------*/
 .opportunity-item{
    display: flex;
    flex-direction: column;
    padding: 16px ; //20px;
    width: 100%;
    border-bottom: 1px solid #eee;
    .MuiButton-containedPrimary{
      width: 50% !important;
      margin-top: 20px;
    }
    .MuiCircularProgress-root{
      width: 20px !important;
      height: 20px !important;
      color:#fff
    }
    &__create{
      margin-top: 35px;
      margin-bottom: 16px;
      h1{
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.57;
        letter-spacing: normal;
        text-align: $right;
        color: #2d323e;
      }
      textarea{
        width: 100%;
        border: solid 1px #eaeaea;
         height: 184px;
         margin-top: 15px;
         padding: 10px;
      }
    }
    &__top{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;
          align-items: center;
          .opportunity_standtitle{
            width: 60%;
            @include RLDir(padding,left,32px);
            @include fontstyle ($darkbtntitle,14px,500)
          }
          .opportunitiestitle{
            width: 60%;
            @include RLDir(padding,left,32px);
            @include fontstyle ($darkbtntitle,14px,bold)
          }
          &__job{
                width: 40%;
                display: flex;
                /* align-content: space-between; */
                justify-content: center;
                font-size: 14px;
                color: #9F9F9F;
                button{
                  font-size: 12px;
                }
                svg{
                  fill:#9F9F9F !important;
                  font-size: 20px !important;
                  margin-left: 3px;

                }
               
          }
        &__location {
            display: flex;
            width: 50%;
            align-items: center;
            @include RLDir(padding,right,16px);
            @include fontstyle ($gray,14px,300);
            &__overflow{
              height: 20px;
              overflow: hidden;
            }
        }
        &__name{
        font-size: 14px;
        color: #9f9f9f;
        color: #e0e0e0;
        display: flex;
       }
    }
    &__description{
      width: 100%;
      line-height: 2;
      margin-bottom: 16px;
      @include fontstyle ($darkgray,12px,300) 
    }
    
  }
  
  .opportunities-headers{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #9f9f9f;
    padding: 15px;
    padding-top: 20px;
   &__left{
     width: 35%;
     display: flex;
     flex-direction: row;
     &__left__city{
          width: 50%;
          text-align: $left;
     }
   }
  }
  /*--------------------------------------------------------*/
  .send-request{
    &__header{
      height: 72px;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      color: #2D323E;
      padding: 0px 15px;
      align-items: center;
      border-bottom: solid 1px #eaeaea;
    }
    &__title{
      @include fontstyle ($darkbtntitle,14px,bold) 
    }
    &__iconinfo{
      .opportunity-item__top{
        &__job{
          width: 100%;
          justify-content: start;
          margin: 16px 0px;
        }
        &__location {
          width:auto;
        }
      }
    }
    &__content{
      @include RLDir(padding,right,16px);
      @include RLDir(padding,left,16px);
      background-color: white !important;
      padding-bottom: 16px;
      .oppsdownloadbtn{
        margin-bottom: 20px;
        .MuiAlert-message{
          width: 100%;
        }
        span , .MuiAlertTitle-root{
          font-size: 12px !important;
        }
        a{
          margin: 0px 5px;
          color: #0d3c61;
        }
      }
      &__tags{
        margin-bottom: 16px;
        span{
          @include fontstyle ($darkgray,12px,300) 
        }
      }
      &__desc{
        width: 100%;
        margin-bottom: 32px !important;
        @include fontstyle ($darkgray,12px,300) 
      }
      &__input{
        p{
          margin-bottom: 8px;
          @include fontstyle ($darkbtntitle,14px,300) 
        }
        margin-bottom: 16px;
      }
    }

  }

  @media(max-width:600px){
    .uploadbox{
      align-items: center;
    }
    .send-request__content .oppsdownloadbtn{
      span , .MuiAlertTitle-root{
        font-size: 12px;
      }
    }
    .mainpage , .rightside{
      height: calc(100vh - 186px) !important;
    }
    .opportunitypage {
      height: calc(100vh - 186px) !important;
      &__content {
        height: calc(100vh - 241px) !important;
      }
    }
    .opportunitypage{
      .table_desgin{
         height: auto !important;
       }
    }
    .opportunity-item{
      padding: 15px;
      &__top{
        flex-direction: column;
        .opportunity_standtitle{
          width: 100%;
          @include RLDir(padding,left,0px);
          @include fontstyle ($darkbtntitle,14px,500)
        }
        .opportunitiestitle{
          width: 100%;
          @include RLDir(padding,left,0px);
          @include fontstyle ($darkbtntitle,14px,bold)
        }
        &__job{
          margin-top: 8px;
          width: 100%;
          justify-content: start;
           
          svg{
            font-size: 18px !important;
          }
        }
        &__location{
          font-size: 12px !important;
          @include RLDir(padding,right,0px); 
          width: auto;
          @include RLDir(margin,left,5px); 
        }
      }
      &__description{
        width: 100%;
        @include RLDir(margin,bottom,10px);
        @include RLDir(margin,right,0px);
      }
      // button{
      //   width: 95%;
      //   margin: 0 auto;
      // }
      
      .mysuggest{
        width: 55% !important;
      }
    }
    .allsuggest{
      width: 95% !important;
      margin: 0px 2.5%!important;
    }
    .disabledbtn{
      width: 95% !important;
    }
    .standsuggest{
      width: 95% !important;
      margin: 0px 2.5%!important;
    }
    .send-request .opportunity-item__top__job{
      @include RLDir(margin,right,16px);
    }
    .autowith{
      width: 95% !important;
      margin: 0 auto !important;
    }
  }