.confirmmodal{
    &__title h2{
        @include fontstyle ($darkgray,14px,bold)
    }
    &__desc{
        
    }
    &__agree{
        @include fontstyle ($darkbluebtn,16px,bold)
    }
    &__disagree{
        @include fontstyle ($gray,16px,bold)
    }
}