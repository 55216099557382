.comments-footer{
    display: flex;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    // left: 0;
    // right: 0;
    width: 100%;
  }
.comment-send-box {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    height:72px; //80px;
    background-color: #EAEAEA;
    align-items: center;
    input {
      width: 90%;
      height: 40px;
      border: 1px solid #eee;
      border-radius: 10px;
      @include RLDir("padding","right",30px);
    }
    .send-box-emoji {
      cursor: pointer;
      @include RLDir("padding","left",10px);
    }
    .send-box-icon {
      width: 55px;
      /* text-align: center; */
      cursor: pointer !important;
      background: #2ea5d9;
      border-radius: 50%;
      /* height: 55px; */
      @include RLDir("margin","right",20px);
      /* padding-left: 5px; */
      padding-top: 10px;
      /* padding: 16px; */
      @include RLDir("padding","right",13px);
      svg {
        fill: #fff;
        font-size: 2rem !important;
        transform: rotate(180deg);
      }
    }
  }
  
  .comment-send-input {
    width: 100%;
    height: 100px;
    margin:0px; //8px 0 15px;
    padding: 8px; //16px;
    border-radius: 10px;
    background-color: #FFFFFF;
  }
  
  .comment-send-icon {
    width: 40px;
    height: 40px;
    background: #475974;
    border-radius: 50%;
    @include RLDir("margin","right",10px);
    display: block;
    text-align: center;
    padding-top: 7px;
    svg {
      fill: #fff;
      margin: 0 10px;
      display: block;
      transform: rotate(180deg);
      font-size: 23px;
    }
    cursor: pointer;
  }
  

  @media(max-width:600px){
    .comment-send-box {
        padding: 0px 8px;
        input{
            @include RLDir("padding","right",8px);
        }
    }
  }