.bluebtn {
    cursor: pointer;
    border: solid 1px #30a4e2 !important;
    @include btnstyle($bluebtn, $lightcolor, 14px, bold, 36px, auto);
    &:hover {
        background-color: #57c0ed !important;
    }
}

.darkbtn {
    border: solid 1px #707070 !important;
    @include btnstyle($darkbtncolor, $lightcolor, 14px, bold, 36px, auto);
    &:hover {
        background-color: $darkbtncolor !important;
    }
}