.fileupload{
    margin-bottom: 16px;
    border: 2px dashed silver;
    text-align: center;
    width: 100%;
    align-items: center;
    display: block;
    justify-content: center;
    border-radius: 5px;
    padding: 16px;
    &_text{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;
      @include fontstyle ($darkbtntitle,14px,300) 
      svg{
        margin-left: 8px;
      }
    }
 }