.expo_container {
  background-image: linear-gradient(
    135deg,
    rgba(122, 219, 252, 1) 0%,
    rgba(20, 174, 242, 1) 53%,
    rgba(2, 94, 233, 1) 100%
  ) !important;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.expo-container {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover;
  background-color: #f0f0f1;
}

.webgl-content,
#loadingBlock,
#errorBrowserBlock {
  padding: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f0f0f1;
}

#gameContainer,
canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #f0f0f1;
}

#fullScreenButton {
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  right: 5px;
  background-color: transparent;
  background-image: url("./TemplateData/img/fullScreen_on.png");
  background-size: 30px 30px;
  border: none;
  cursor: pointer;
}

.subtitle {
  color: #1896a7;
  font-size: 2.5vh;
  padding-bottom: 3vh;
  padding-top: 3vh;
  display: block;
  height: 15vh;
  width: 40vw;
  margin: auto;
  text-align: center;
}

.logo {
  width: 50%;
  margin-bottom: 16px;
}

#loadingBlock,
#errorBrowserBlock {
  background-image: url("./TemplateData/img/background.jpg");
  background-size: cover;
}

#emptyBar {
  background: url("./TemplateData/img/progressEmpty.png") no-repeat $right;
  float: $right;
  width: 60%;
  height: 100%;
  display: inline-block;
}

#fullBar {
  background: url("./TemplateData/img/progressFull.png") no-repeat $right;
  float: $left;
  width: 40%;
  height: 100%;
  display: inline-block;
}

#progressBar,
#warningBrowserBlock,
#warningMobileBlock,
#errorContent {
  width: 100%;
  padding: 0 16px;
  text-align: center;
}

#progressBar {
  color: #ffffff;
  font-size: 4vh;
  height: 6px;
}

#warningBrowserBlock,
#warningMobileBlock,
#errorContent {
  margin-top: 15vh;
  color: #666666;
  font-size: 2.3vh;
}

.browserIcons {
  display: inline-flex;
  margin-top: 2vh;
}

.browserIcons a {
  width: 150px;
}

#errorContent {
  font-size: 3vh;
  margin-top: 5vh;
}

.centered {
  height: 100%;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}
