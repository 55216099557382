// colors
$white: #fff;
$green: #6ffbba;
$black: #000;
$base-dark: #475974;
// $dark-gray:#9f9f9f;
$main-primary: #2d323e;
$background-color: #eaeaea;
/*-------*/
$bluebtn: #30a4e2;
$lightcolor: #fafafa;
$darkbtncolor: #475974;
$disablebtn: #b6d9f4;
$greenbtn: #13c356;
$darkbluebtn: #2ea5d9;
$gray: #9f9f9f;
//--------------------color from design system-------------------------
$dark-base: #444444;
$gray: #bdbdbd;
$gray-btn: #fafafa;
$white-bg: #eeeeee;
$gray-lightest: #fafafa;
$blue-dark: #2f3341;
$blue-dark-hover: #393f52;
$blue: #2da5d9;
$blue-hover: #57c0ed;
$green: #339900;
$green-hover: #3aaf00;
$red: #d91a3c;
$red-hover: #f22e52;
$base-yellow: #ffcc00;

$brand-color: $blue;
$brand-color-font-complete: #ffffff;
//---------------Spacing-----------------------
$padding-base: 15px;
$padding-small: 10px;
$margin-base: 15px;
$margin-small: 10px;
//--------text------------------
$text-base-color: $dark-base;
//---------disabled-------------------------------
$disabled-bg: $gray;
$disabled-color: $dark-base;
//---------active-----------------------------
$active-bg: $blue-dark;
$active-color: #fff;
//---------------------------------------------
$container-large-desktop: 1400px !default;
$container-desktop: 1024px !default;
$container-large-tablet: 992px !default;
$container-tablet: 768px !default;
$container-large-mobile: 600px !default;
$container-sm: 576px !default;
$container-mobile: 480px !default;
//-----------------font size-------------------------
$font-size-xl: 18px;
$font-size-lg: 16px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xs: 10px;

//---------------------------------------------
$h-page-title-size: $font-size-md;
$table-hover: $gray-lightest;
//----------------- shadow ----------------------------
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 16px;
$border-radius-xl: 24px;
//----------------- btn icon ----------------------------
$btn-icon-w-md: 40px;
$btn-icon-w-lg: 64px;
//----------------- shadow ----------------------------
$shadow-color: rgba(0, 0, 0, 0.16);
$shadow-color-lightest: rgba(0, 0, 0, 0.08);
$shadow-down: 0 2px 8px $shadow-color;
$shadow-middle: 0  16px 24px $shadow-color;
$shadow-middle-light: 0 16px 24px $shadow-color-lightest;
$shadow-up: 0 16px 36px $shadow-color;
$shadow-footer: 0 -2px 8px $shadow-color;
//----------------- btn ----------------------------
$btn-box-shadow:$shadow-down;

$btn-attention-bg: $red;
$btn-attention-bg-hover: $red-hover;
$btn-attention-font: #fff;

$btn-default-bg: $blue-dark;
$btn-default-bg-hover: $blue-dark-hover;
$btn-default-font: #fff;

$btn-action-bg: $blue;
$btn-action-bg-hover: $blue-hover;
$btn-action-font: #fff;

$btn-success-bg: $green;
$btn-success-bg-hover: $green-hover;
$btn-success-font: #fff;

$btn-disabled-bg: $gray;
$btn-disabled-bg-hover: $gray;
$btn-disabled-font: rgba($blue-dark,0.8);
//-----------------------------------------
$stands_background: #fefefe;
$stand-item-shadow:$shadow-middle-light;
$stand-item-shadow-hover:$shadow-up;
$stand-item-z-index:5;
//----------------------------------
body {
    &[dir="rtl"] {
        --dir: rtl;
        --dir-ltr: ltr;
        --left: left;
        --right: right;
        --transform-direction: 1;
    }
    &[dir="ltr"] {
        --dir: ltr;
        --dir-ltr: rtl;
        --left: right;
        --right: left;
        --transform-direction: -1;
    }
}

@mixin RLDir($prop, $dir, $value) {
    @if ($prop!=null) {
        @if ($dir==left) {
            body[dir="rtl"] & {
                #{$prop}-left: $value;
            }
            body[dir="ltr"] & {
                #{$prop}-right: $value;
            }
        } @else {
            body[dir="rtl"] & {
                #{$prop}-right: $value;
            }
            body[dir="ltr"] & {
                #{$prop}-left: $value;
            }
        }
    } @else {
        @if ($dir==left) {
            body[dir="rtl"] & {
                left: $value;
            }
            body[dir="ltr"] & {
                right: $value;
            }
        } @else {
            body[dir="rtl"] & {
                right: $value;
            }
            body[dir="ltr"] & {
                left: $value;
            }
        }
    }
}

$dir: var(--dir);
$dir-ltr: var(--dir-ltr);
$left: var(--left);
$right: var(--right);
$transform-direction: var(--transform-direction);

$scroll-base-width: 10px;
$scroll-base-bg: #fafafa;
$scroll-base-thumb-color: $darkbluebtn;
$scroll-base-thumb-color-hover: darken($scroll-base-thumb-color, 10%);

$avatar-min-width: 96px;
