body {
  @extend %scrollBase;
  overflow-y: auto;
}
.MuiDialog-root {
  z-index: 99999 !important;
}
.non-radius {
  border-radius: 0px !important;
}
/*------------------------------------------------*/
.tab_header {
  width: 100%;
  .MuiTabs-root,
  .MuiTabs-flexContainer {
    width: 100%;
    height: 72px !important;
    box-shadow: none !important;
    border-bottom: 1px solid #eee !important;
    background-color: #fff !important;
  }
  .MuiTab-wrapper {
    @include fontstyle($gray, 16px, bold);
  }
  .MuiTabs-indicator {
    height: 3px;
    color: $bluebtn;
  }
  .MuiTab-textColorPrimary.Mui-selected span,
  .MuiTab-textColorInherit.Mui-selected span {
    color: $darkbtntitle !important;
  }
}
/*---------------------------------------------*/
.table_desgin {
  height: calc(100vh - 182px);

  .MuiTableCell-head {
    @include fontstyle($gray, 14px, bold);
    height: 70px !important;
  }
  .MuiTableCell-body {
    @include fontstyle($darkbtntitle, 14px, 200);
  }
  .MuiTypography-displayBlock {
    @include fontstyle($darkbtntitle, 14px, bold);
  }
  .MuiTableRow-root {
    height: 70px;
    .MuiTableCell-body{
      @media (max-width: $container-sm) {
padding-left: 8px;
padding-right: 8px;
      }
    }
  }
  &.clickable {
    .MuiTableRow-root {
      &:hover {
        background-color: $table-hover;
        cursor: pointer;
      }
    }
  }
}
// .MuiTab-root {
//   font-weight: 400;
// }

.sidepage {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

@media (max-width: 960px) {
  .table_desgin {
    height: calc(100vh - 241px) !important;
    max-height: calc(100vh - 241px) !important;
    .MuiTableCell-head {
      font-size: 12px !important;
    }
    .MuiTableCell-head,
    .MuiTableHead-root tr {
      height: 56px !important;
    }
    .MuiTypography-colorTextSecondary {
      @include fontstyle($gray, 10px, bold);
      line-height: 2;
    }
    .MuiTableCell-body {
      @include fontstyle(null, 12px, 300);

      .MuiCardHeader-root {
        flex-direction: row !important;
        span:first-child {
          @include fontstyle($darkbtntitle, 12px, 500);
        }
      }
      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        @include fontstyle(null, 12px, 300);
        span {
          @include fontstyle($gray, 10px, bold);
          line-height: 2;
        }
      }
    }
  }
  .tab_header {
    .MuiTabs-root,
    .MuiTabs-flexContainer {
      height: 56px !important;
    }
    .MuiTab-wrapper {
      font-size: 14px !important;
    }
  }
  .Responsive_Filter_Mood {
    width: 100%;
    height: auto;
    padding: 0px 0 34px;
    border-left: none;
  }
}

@media (max-width: 600px) {
  .makeStyles-contentContainer-3 {
    background-color: white;
  }

  /*----------------------------------*/

  .fftabs {
    .MuiTab-root {
      min-width: 50%;
      max-width: 50%;
    }
  }
}
