defs pattern,
defs pattern image {
  position: relative;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

$light-color: var(--light-color);
$main-color: var(--main-color);
$dark-color: var(--dark-color);


.dlbtnbox {
  height: 46px;
  position: relative;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    height: 34px;
  }
}
.downloadbtn {
  position: absolute;
  z-index: 9;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.pdfbtn {
  bottom: 12px;
  left: 12px;
}
.non-shadow{
  box-shadow: 0 0 0 0 !important;
}
.typea_container {
  // height: 60vh;
  height: 100%;
  background-color: $stands_background !important;
}

.typec_container {
  // height: 600px;
  height: 100%;
  background-color: $stands_background !important;
}

.typec_container svg {
  padding-left: 45px;
}

.typeb_container {
  // height: 600px;
  height: 100%;
  background-color: $stands_background !important;
}

.typeb_container svg {
  padding-left: 45px;
}

defs pattern image.clogo {
  x: 0.12;
  y: 0;
  transform: rotateX(0deg) rotateZ(0deg) scale(0.8) skewY(10deg);
}

defs pattern image.blogo {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(13deg) scaleX(0.7)
    scaleY(0.65);
  x: 0.1;
  y: 0.04;
}

defs pattern image.docs-cover {
  transform: translateX(0.1px) translateY(0.35px) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) skewY(-4deg) scaleX(0.8) scaleY(0.3);
}

defs pattern image.docs-cover-top-left {
  transform: translateX(0.07px) translateY(-0.13px) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) skewY(12deg) skewX(-7deg) scaleX(1) scaleY(1);
}

defs pattern image.docs-cover-top-right {
  transform: translateX(0.04px) translateY(0.07px) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) skewY(-12deg) skewX(-4deg) scaleX(1) scaleY(1);
}

defs pattern image.avideo {
  transform: translateY(0.15px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skewY(-18deg) scale(1);
}

defs pattern image.avideo_icon {
  x: 0.8;
  y: 0.8;
  transform: translateY(0.15px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skewY(-18deg) scale(0.4);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.avideotop {
  transform: translateY(0.12px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skewY(-13deg) scale(1);
}

defs pattern image.aposter {
  transform: translateY(-0.09px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skewY(5deg) scale(1);
}

defs pattern image.aposter_top {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(8.1deg) scale(1);
}

defs pattern image.aposter_left {
  transform: translateY(0.03px) rotateX(0deg) rotateY(0deg) rotateZ(0deg)
    skewY(-6.5deg) scale(1);
}

defs pattern image.bposter {
  // x: -0.2;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scaleX(1)
    skewY(1deg);
}

defs pattern image.bvideo {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(-5deg);
}

defs pattern image.bvideo_icon {
  x: 0.8;
  y: 0.8;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(-5deg) scale(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

defs pattern image.bcatalog {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(2deg) scale(1);
}

defs pattern image.cposter {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(1);
}

defs pattern image.catalog_cover_c {
  x: -0.02;
  y: 0;
  transform: skewX(1.5deg) skewY(0deg) scale(1);
}

defs pattern image.cvideo {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(1);
}

defs pattern image.aposter_icon {
  x: 0.8;
  y: 0.6;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(5deg) scaleX(0.4)
    scaleY(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.bposter_icon {
  x: 0.8;
  y: 1.45;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(1deg) scaleX(0.4)
    scaleY(0.25);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.bposter_icon_right {
  x: 0.8;
  y: 1.45;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(1deg) scaleX(0.4)
    scaleY(0.25);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.bcatalog_icon {
  x: 0.4;
  y: 0;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(1deg) scaleX(0.6)
    scaleY(0.9);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.ccatalog_icon {
  x: 0.8;
  y: 0.75;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.cposter_icon {
  x: 0.8;
  y: 0.75;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.cvideo_icon {
  x: 0.8;
  y: 0.75;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(0deg) scale(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.aposter_icon_left {
  x: 0.8;
  y: 2.2;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(-6deg) scaleX(0.4)
    scaleY(0.2);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.aposter_icon_top {
  x: 0.8;
  y: 0.35;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(9deg) scaleX(0.4)
    scaleY(0.4);
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

defs pattern image.alogo_a {
  x: 0.45;
  y: 0.85;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(-30deg) scale(0.43)
    scaleY(1.2);
}

defs pattern image.alogo_b {
  x: 0.98;
  y: 1.25;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewY(-31deg) scale(0.43)
    scaleY(1.2);
}

.row > .column {
  padding: 0 8px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Create four equal columns that floats next to eachother */
.column {
  float: left;
  width: 25%;
}

/* Modal Content */
.video-modal {
  z-index: 9999;
  .MuiDialog-paperFullScreen {
    width: calc(100% - 240px);
    @include RLDir(margin, right, auto);
    .modal-content {
      width: 100% !important;
      height: 100% !important;
      max-width: unset !important;
      .content {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid silver;
  .MuiIconButton-root {
    padding: 0px;
  }
  // .MuiDialog-paperWidthSm {
  //   width: 600px !important;
  // }
}
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  max-width: 600px;
  width: 600px;
  padding: 8px 8px !important;
  img {
    object-fit: contain;
    width: 100%;
    height: 97%;
    overflow: hidden;
    max-height: 512px;
  }

  .content {
    width: 584px;
    height: 420px;
  }
  .qier-player-container .qier-player {
    background-color: #fff;
  }
}
.modal-img {
  height: auto;
}
@media (max-width: 600px) {
  .video-modal {
    .MuiDialog-paper {
      // margin: 0px 12px;
    }
    .modal-content .content {
      width: 100%; //314px;
      height: 250px;
    }
    .MuiDialog-paperFullScreen {
      width: 100%;
      .modal-content .content {
        width: 100%;
        height: 100%;
      }
    }
  }
  .modal-content {
    width: 300px;
  }
  .modal-img {
    height: 300px !important;
  }
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Hide the slides by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

g:nth-child(50) path:first-child,
g:nth-child(451) path:first-child {
  fill: $dark-color;
}

.typea_container g:nth-child(49) path:first-child,
.typea_container g:nth-child(450) path:first-child {
  fill: $dark-color;
}

.typeb_container g:nth-child(67) path:first-child {
  fill: $main-color;
}

g:nth-child(257) path:first-child,
.typea_container g:nth-child(176) path:first-child,
.typea_container g:nth-child(267) path:first-child {
  fill: white;
}

// TYPE C

path[data-code="STDC040"] {
  fill: $dark-color;
}

path[data-code="STDC009"] {
  fill: url(#gradient_2);
}

path[data-code="STDC011"] {
  fill: $light-color;
}

path[data-code="STDC038"] {
  fill: url(#gradient_3);
}

path[data-code="STDC049"] {
  fill: $dark-color;
}

path[data-code="STDC002"],
path[data-code="STDC010"],
path[data-code="STDC012"],
path[data-code="STDC037"] {
  fill: #dde0e8;
}

path[data-code="STDC004"],
path[data-code="STDC006"],
path[data-code="STDC016"],
path[data-code="STDC042"] {
  fill: #bdbfc6;
}

path[data-code="STDC029"],
path[data-code="STDC046"],
path[data-code="STDC048"],
path[data-code="STDC025"],
path[data-code="STDC041"] {
  fill: #80828e;
}

path[data-code="STDC026"],
path[data-code="STDC031"],
path[data-code="STDC045"],
path[data-code="STDC017"],
path[data-code="STDC032"],
path[data-code="STDC027"],
path[data-code="STDC030"] {
  fill: url(#gradient_1);
}

path[data-code="STDC043"],
path[data-code="STDC044"] {
  fill: #5c5e68;
}

path[data-code="STDC019"] {
  fill: url(#gradient_4);
}

path[data-code="STDC013"] {
  fill: url(#gradient_5);
}

path[data-code="STDC038"] {
  fill: url(#gradient_6);
}

path[data-code="STDC047"] {
  fill: #efeff4;
}

path[data-code="STDC023"] {
  fill: #4c4e56;
}

path[data-code="STDC007"],
path[data-code="STDC028"],
path[data-code="STDC039"] {
  fill: #cccccc;
}

path[data-code="STDC015"],
path[data-code="STDC018"],
path[data-code="STDC035"] {
  fill: #cccccc;
}

path[data-code="STDC005"],
path[data-code="STDC099"],
path[data-code="STDC055"],
path[data-code="STDC008"],
path[data-code="STDC036"],
path[data-code="STDC050"],
path[data-code="STDC088"] {
  cursor: pointer;
}

path[data-code="STDC052"] {
  fill: #aeb1bb;
}

path[data-code="STDC053"] {
  fill: #656670;
}

// TYPE B
path[data-code="STDB003"],
path[data-code="STDB034"],
path[data-code="STDB047"] {
  fill: $dark-color;
}

path[data-code="STDB002"],
path[data-code="STDB005"],
path[data-code="STDB046"] {
  fill: $main-color;
}

path[data-code="STDB030"] {
  fill: #80828e;
}

path[data-code="STDB053"],
path[data-code="STDB021"] {
  fill: url(#gradient_1);
}

path[data-code="STDB002"] {
  fill: url(#gradient_2);
}

path[data-code="STDB045"] {
  fill: url(#gradient_3);
}

path[data-code="STDB006"],
path[data-code="STDB004"] {
  fill: #9ea1ad;
}

path[data-code="STDB061"] {
  fill: #bdbfc6;
}

path[data-code="STDB072"],
path[data-code="STDB073"] {
  fill: #dde0e8;
}

path[data-code="STDB070"],
path[data-code="STDB071"] {
  fill: #bdbfc6;
}

path[data-code="STDB066"],
path[data-code="STDB069"] {
  fill: #d6e2e0;
}

path[data-code="STDB038"] {
  fill: #666666;
}

path[data-code="STDB040"] {
  fill: #666666;
}

path[data-code="STDB057"] {
  fill: #666666;
}

path[data-code="STDB059"],
path[data-code="STDB060"],
path[data-code="STDBI059"],
path[data-code="STDBI060"],
path[data-code="STDB067"],
path[data-code="STDB074"],
path[data-code="STDB075"] {
  cursor: pointer;
}

path[data-code="STDB022"],
path[data-code="STDB052"],
path[data-code="STDB054"],
path[data-code="STDB063"] {
  fill: #cccccc;
}

path[data-code="STDB016"],
path[data-code="STDB017"],
path[data-code="STDB018"],
path[data-code="STDB029"],
path[data-code="STDB035"],
path[data-code="STDB036"],
path[data-code="STDB037"],
path[data-code="STDB019"],
path[data-code="STDB031"],
path[data-code="STDB033"],
path[data-code="STDB058"],
path[data-code="STDB055"],
path[data-code="STDB056"] {
  fill: #80828e;
}

path[data-code="STDB024"],
path[data-code="STDBI024"] {
  cursor: pointer;
}

path[data-code="STDB027"],
path[data-code="STDB064"] {
  fill: #666666;
}

path[data-code="STDB065"] {
  fill: #bfbfbf;
}

path[data-code="STDB068"] {
  fill: #cccccc;
}

path[data-code="STDB039"],
path[data-code="STDB062"] {
  fill: #999999;
}

// TYPE A
path[data-code="STDA014"],
path[data-code="STDA030"],
path[data-code="STDA451"] {
  fill: #8d8f99;
}

path[data-code="STDA490"],
path[data-code="STDA491"],
path[data-code="STDA475"],
path[data-code="STDA479"],
path[data-code="STDA477"],
path[data-code="STDA474"],
path[data-code="STDA478"],
path[data-code="STDA481"] {
  fill: #8d8f99;
  opacity: 0.4;
}

// path[data-code="STDA049"],
// path[data-code="STDA450"],
path[data-code="STDA401"],
path[data-code="STDA422"] {
  fill: #80828e;
}

path[data-code="STDA430"],
path[data-code="STDA437"] {
  fill: $dark-color;
}

path[data-code="STDA013"] {
  fill: #e2e2ed;
}

path[data-code="STDA471"],
path[data-code="STDA472"] {
  fill: #c4e1f8;
  opacity: 0.8;
}
path[data-code="STDA449"] {
  fill: #207bac;
  opacity: 0.3;
}

path[data-code="STDA191"] {
  fill: url(#gradient_2);
}

path[data-code="STDA155"],
path[data-code="STDA194"] {
  fill: url(#gradient_1);
}

path[data-code="STDA279"],
path[data-code="STDA428"],
path[data-code="STDA424"],
path[data-code="STDA419"] {
  fill: #d0d0dd;
}

path[data-code="STDA421"] {
  fill: #8d8f99;
}

path[data-code="STDA051"] {
  fill: #cccccc;
}

path[data-code="STDA047"] {
  fill: #cccccc;
}

path[data-code="STDA022"] {
  fill: #a3a6af;
}

// khjkhih
path[data-code="STDA328"] {
  fill: #a3a6af;
}

path[data-code="STDA416"],
path[data-code="STDA418"],
path[data-code="STDA454"],
path[data-code="STDA420"] {
  fill: #f2f2f7;
}

path[data-code="STDA256"],
path[data-code="STDA436"],
path[data-code="STDA452"] {
  fill: $main-color;
}

path[data-code="STDA502"],
path[data-code="STDA503"] {
  cursor: pointer;
}

path[data-code="STDA453"] {
  fill: url(#gradient_2);
}

path[data-code="STDA294"],
path[data-code="STDA327"],
path[data-code="STDA402"],
path[data-code="STDA403"],
path[data-code="STDA404"],
path[data-code="STDA405"] {
  fill: #cccccc;
}

path[data-code="STDA455"],
path[data-code="STDA460"],
path[data-code="STDA462"],
path[data-code="STDA464"],
path[data-code="STDA434"],
path[data-code="STDA439"],
path[data-code="STDA441"],
path[data-code="STDA443"],
path[data-code="STDA445"] {
  fill: #a5a5a5;
}

path[data-code="STDA293"],
path[data-code="STDA298"],
path[data-code="STDA274"],
path[data-code="STDA406"] {
  fill: #f2f2f2;
}

path[data-code="STDA292"],
path[data-code="STDA297"],
path[data-code="STDA273"] {
  fill: #a5a5a5;
}

path[data-code="STDA024"],
path[data-code="STDA026"],
path[data-code="STDA054"],
path[data-code="STDA095"],
path[data-code="STDA097"],
path[data-code="STDA189"] {
  fill: #a5a5a5;
}

path[data-code="STDA019"],
path[data-code="STDA020"],
path[data-code="STDA050"],
path[data-code="STDA017"] {
  fill: #bdbfc6;
}

path[data-code="STDA021"] {
  fill: #f2f2f2;
}

path[data-code="STDA021"] {
  fill: #f2f2f2;
}

path[data-code="STDA016"],
path[data-code="STDA407"],
path[data-code="STDA414"] {
  fill: $light-color;
  opacity: 0.5;
}

path[data-code="STDA407"] {
  fill: #f2f2f2;
}

path[data-code="STDA431"] {
  fill: url(#gradient_3);
}

path[data-code="STDA001"],
path[data-code="STDA469"],
path[data-code="STDA004"],
path[data-code="STDA012"],
path[data-code="STDA007"],
path[data-code="STDA008"] {
  fill: #ddf3ff;
}

path[data-code="STDA423"] {
  fill: #7c7d7c;
}

path[data-code="STDA411"],
path[data-code="STDA412"],
path[data-code="STDA413"],
path[data-code="STDA417"] {
  fill: #d1d2db;
}

path[data-code="STDA120"],
path[data-code="STDA175"],
path[data-code="STDA102"],
path[data-code="STDA307"],
path[data-code="STDA238"],
path[data-code="STDA284"],
path[data-code="STDA358"],
path[data-code="STDA316"],
path[data-code="STDA213"],
path[data-code="STDA438"] {
  fill: #dfe0e8;
}

path[data-code="STDA277"],
path[data-code="STDA288"],
path[data-code="STDA280"],
path[data-code="STDA310"],
path[data-code="STDA331"],
path[data-code="STDA251"],
path[data-code="STDA247"],
path[data-code="STDA263"],
path[data-code="STDA286"],
path[data-code="STDA312"],
path[data-code="STDA329"],
path[data-code="STDA315"],
path[data-code="STDA333"],
path[data-code="STDA198"],
path[data-code="STDA210"],
path[data-code="STDA203"],
path[data-code="STDA214"],
path[data-code="STDA216"],
path[data-code="STDA197"],
path[data-code="STDA234"],
path[data-code="STDA226"],
path[data-code="STDA236"],
path[data-code="STDA239"],
path[data-code="STDA259"],
path[data-code="STDA232"],
path[data-code="STDA254"],
path[data-code="STDA261"],
path[data-code="STDA209"],
path[data-code="STDA233"],
path[data-code="STDA228"],
path[data-code="STDA245"],
path[data-code="STDA249"],
path[data-code="STDA229"],
path[data-code="STDA200"],
path[data-code="STDA244"],
path[data-code="STDA269"],
path[data-code="STDA087"],
path[data-code="STDA091"],
path[data-code="STDA089"],
path[data-code="STDA076"],
path[data-code="STDA043"],
path[data-code="STDA062"],
path[data-code="STDA151"],
path[data-code="STDA143"],
path[data-code="STDA145"],
path[data-code="STDA149"],
path[data-code="STDA147"],
path[data-code="STDA093"] {
  fill: #dfe0e8;
}

path[data-code="STDA083"],
path[data-code="STDA435"],
path[data-code="STDA275"],
path[data-code="STDA282"] {
  fill: #cccccc;
}

path[data-code="STDA230"],
path[data-code="STDA295"],
path[data-code="STDA372"] {
  fill: #bfbfbf;
}

path[data-code="STDA350"],
path[data-code="STDA360"] {
  fill: #d3d3d3;
}

path[data-code="STDA045"],
path[data-code="STDA174"],
path[data-code="STDA031"],
path[data-code="STDA348"],
path[data-code="STDA389"],
path[data-code="STDA271"],
path[data-code="STDA366"],
path[data-code="STDA319"],
path[data-code="STDA074"],
path[data-code="STDA241"],
path[data-code="STDA488"],
path[data-code="STDA486"],
path[data-code="STDA128"],
path[data-code="STDA224"] {
  fill: #dfdfdf;
}

path[data-code="STDA049"],
path[data-code="STDA450"] {
  filter: drop-shadow(-3px 2px 1.5px rgba(0, 0, 0, 1));
}

path[data-code="STDB067"] {
  filter: drop-shadow(3px 1px 4px rgba(0, 0, 0, 3));
}

path[data-code="STDAIC000"],
path[data-code="STDAIC101"],
path[data-code="STDAIC415"],
path[data-code="STDAIC433"],
path[data-code="STDA049"],
path[data-code="STDA450"],
path[data-code="STDA176"],
path[data-code="STDA101"],
path[data-code="STDA000"],
path[data-code="STDA415"],
path[data-code="STDA459"],
path[data-code="STDA432"],
path[data-code="STDA433"],
path[data-code="STDA267"],
path[data-code="STDA484"] {
  cursor: pointer;
}

path[data-code="STDA183"],
path[data-code="STDA290"],
path[data-code="STDA070"],
path[data-code="STDA125"],
path[data-code="STDA317"],
path[data-code="STDA340"],
path[data-code="STDA099"],
path[data-code="STDA160"],
path[data-code="STDA211"],
path[data-code="STDA138"] {
  fill: #cfcfcf;
}

@media (max-width: 960px) {
  .typec_container svg {
    padding-left: 22px;
  }
}

.wrapper {
  margin: 4em auto;
  width: 37em;

  transform: perspective(450px) rotateY(45deg);
}

.inner figure {
  width: 11em;
  padding: 1em;
  display: inline-block;
}
.inner img {
  display: block;
  height: auto;
  max-width: 100%;
}
